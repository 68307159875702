import React, { useState } from "react";

import Widget from "../../Widget/Widget";
import DatePicker from "react-datepicker";
import {
  Col,
  Row,
  Button,
  ButtonGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Form,
  FormGroup,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  Badge,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  POST_USER,
  API_TIMEOUT,
  GET_USER,
  BANTUAN_TAHUN_API,
  BANTUAN_API,
  BANTUAN_DINAS_API,
  VALIDASI_INPUT_BANTUAN,
  BANTUAN_JENIS_API,
  VALIDASI_INPUT_JENIS_BANTUAN,
  POST_BANTUAN_JENIS,
} from "../../../utils";
import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router";
import s from "./Elements.module.scss";

import { useDispatch } from "react-redux";
import { GET_DESA, GET_KECAMATAN } from "../../../utils";
import { push } from "connected-react-router";
import { logApp } from "../../../utils/func";

import swal from "sweetalert";

const JenisBantuanForm = () => {
  const [startDate, setStartDate] = useState("");

  const [bantuan, setBantuan] = useState("");
  const [dinas, setDinas] = useState("");
  const [bentuk, setBentuk] = useState("");
  const [target, setTarget] = useState("");
  const [jenis, setJenis] = useState("");
  const [tahun, setTahun] = useState("2023");
  const [totalDana, setTotalDana] = useState("");
  const [jmlPenerima, setJmlPenerima] = useState("");
  const [jmlPerklp, setJmlPerklp] = useState("");
  const [catatan, setCatatan] = useState("");
  const [sumberDana, setSumberDana] = useState("");

  const [listBantuan, setListBantuan] = useState([]);
  const [listBantuanKu, setListBantuanKu] = useState([]);
  const [listDinas, setListDinas] = useState([]);

  const [validasiBantuan, setValidasiBantuan] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState();

  const { id } = useParams();
  const { idView } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentUser) {
      let currentUser = JSON.parse(localStorage.getItem("user"));
      //untuk kepentngan development

      setCurrentUser(currentUser);
      logApp(currentUser.uid, "Form Bantuan");
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      getBantuan();
      getDinas();
    }
  }, [currentUser]);

  useEffect(() => {
    if (id) {
      cariData(id);
    }
    if (idView) {
      cariData(idView);
    }
  }, [id, idView]);

  useEffect(() => {
    if (dinas) {
      ubahDinas(dinas);
    }
  }, [dinas]);

  const ubahDinas = (dinas) => {
    if (dinas) {
      var listBantuanPilih = listBantuan.filter((item) => {
        return item.dinas == dinas;
      });

      setListBantuanKu(listBantuanPilih);
    }
  };

  const cariData = (id) => {
    const dataKu = { id: id };

    axios({
      method: "get",
      url: BANTUAN_JENIS_API + id,
      timeout: API_TIMEOUT,

      data: dataKu,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const result = response.data.data[0];

          if (response.data.data.length == 0) {
            return;
          }

          setDinas(result.dinas);
          setBantuan(result.bantuan);

          setBentuk(result.bentuk);
          setTarget(result.target);
          setJenis(result.jenis);

          setSumberDana(result.sumberDana);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const validasiInputBantuan = () => {
    axios({
      method: "post",
      url: VALIDASI_INPUT_JENIS_BANTUAN,
      timeout: API_TIMEOUT,

      data: {
        dinas: dinas,
        bantuan: bantuan,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const result = response.data.data;

          if (result.length > 0) {
            setValidasiBantuan(true);
            setModalOpen(true);
          } else {
            setValidasiBantuan(false);
            setModalOpen(true);
          }
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const getBantuan = () => {
    axios({
      method: "get",
      url: BANTUAN_API,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          if (response.data.data.length > 0) {
            const data = response.data.data;

            // memfilter seduai dengan admin
            let dataFilter = [];
            if (currentUser.peran == "admin") {
              dataFilter = data.filter((item) => {
                return item.dinas == currentUser.dinas;
              });
            } else {
              dataFilter = data;
            }

            setListBantuan(dataFilter);
          }
          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  const getDinas = () => {
    axios({
      method: "get",
      url: BANTUAN_DINAS_API,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          if (response.data.data.length > 0) {
            const data = response.data.data;

            let dataFilter = [];
            if (currentUser.peran == "admin") {
              dataFilter = data.filter((item) => {
                return item.dinas == currentUser.dinas;
              });
            } else {
              dataFilter = data;
            }

            setListDinas(dataFilter);
            if (!id && !idView) {
              setDinas(dataFilter[0].dinas);
              ubahDinas(dataFilter[0].dinas);
            }
          }
          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  const editData = async () => {
    const data = {
      dinas: dinas,
      bantuan: bantuan,
      bentuk: bentuk,
      target: target,
      jenis: jenis,
      tahun: tahun,
      sumberDana: sumberDana,
    };

    const response = await axios.patch(BANTUAN_JENIS_API + id, data);

    if (response.data.success === 1) {
      swal("Sukses", "Data Bantuan berhasil diedit", "success");
      dispatch(push("/admin/bantuan/jenis"));
    } else {
      swal("Gagal", "Data Bantuan gagal diedit", "failed");
    }
  };

  const tambahData = async () => {
    const data = {
      dinas: dinas,
      bantuan: bantuan,
      bentuk: bentuk,
      target: target,
      jenis: jenis,
      tahun: tahun,
      sumberDana: sumberDana,
    };

    console.log(data);
    const response = await axios.post(POST_BANTUAN_JENIS, data);

    if (response.data.success === 1) {
      swal("Sukses", "Data Bantuan berhasil diinput", "success");
      setDinas("");
      setBantuan("");
      setBentuk("");
      setTarget("");
      setJenis("");

      setSumberDana("");
    } else {
      swal("Data Bantuan gagal diinput");
    }
  };

  const lanjutkan = () => {
    tambahData();
    setModalOpen(false);
  };

  const simpanData = async (e) => {
    e.preventDefault();
    if (id) {
      editData();
    } else {
      validasiInputBantuan();
    }
  };

  const renderForm = () => (
    <div>
      <Widget className="widget-p-md">
        <div className="headline-2">
          {id ? "Edit" : idView ? "Lihat" : "Input"}
        </div>
        <FormGroup>
          <Form onSubmit={(e) => simpanData(e)}>
            <legend className="mt-2">Data Jenis Bantuan</legend>
            <FormGroup row>
              <Label md={3} for="normal-field" className="text-md-right">
                Dinas/Instansi
              </Label>
              <Col md={9}>
                <Input
                  type="select"
                  id="normal-field"
                  placeholder=""
                  value={dinas}
                  onChange={(e) => setDinas(e.target.value)}
                  required
                >
                  <option value={""}>Pilih Dinas</option>
                  {listDinas &&
                    listDinas.map((item, index) => (
                      <option value={item.dinas} key={index}>
                        {item.dinas}
                      </option>
                    ))}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                md={3}
                for="hint-field"
                className="d-flex flex-column text-md-right"
              >
                Bantuan
                <span className="label muted">nama bantuan</span>
              </Label>
              <Col md={9}>
                <Input
                  type="text"
                  name="password"
                  value={bantuan}
                  onChange={(e) => setBantuan(e.target.value)}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={3} for="normal-field" className="text-md-right">
                Bentuk
              </Label>
              <Col md={9}>
                <Input
                  type="select"
                  id="normal-field"
                  placeholder=""
                  value={bentuk}
                  onChange={(e) => setBentuk(e.target.value)}
                  required
                >
                  <option value={""}> pilih bentuk</option>
                  <option value={"Uang"}>Uang</option>
                  <option value={"Barang"}>Barang</option>
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={3} for="normal-field" className="text-md-right">
                Target
              </Label>
              <Col md={9}>
                <Input
                  type="select"
                  id="normal-field"
                  placeholder=""
                  value={target}
                  onChange={(e) => setTarget(e.target.value)}
                  required
                >
                  <option value={""}> pilih target</option>
                  <option value={"Individu"}>Individu</option>
                  <option value={"Kelompok"}>Kelompok</option>
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={3} for="normal-field" className="text-md-right">
                Jenis
              </Label>
              <Col md={9}>
                <Input
                  type="select"
                  id="normal-field"
                  placeholder=""
                  value={jenis}
                  onChange={(e) => setJenis(e.target.value)}
                  required
                >
                  <option value={""}> pilih jenis</option>
                  <option value={"Hibah"}>Hibah</option>
                  <option value={"Pengembalian"}>Pengembalian</option>
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={3} for="normal-field" className="text-md-right">
                Sumber Dana
              </Label>
              <Col md={9}>
                <Input
                  type="select"
                  id="normal-field"
                  placeholder=""
                  value={sumberDana}
                  onChange={(e) => setSumberDana(e.target.value)}
                  required
                >
                  <option value={""}> pilih sumber dana</option>
                  <option value={"APBD II"}>APBD II</option>
                  <option value={"APBD I"}>APBD I</option>
                  <option value={"APBN"}>APBN</option>
                  <option value={"APBD"}>APBD</option>
                </Input>
              </Col>
            </FormGroup>

            {!idView && (
              <FormGroup row>
                <Label md={3} />
                <Col md={9}>
                  <Button color="primary" type="submit" className="mr-3 mt-3">
                    Save Changes
                  </Button>
                  <Button
                    color="secondary-red"
                    className="mt-3"
                    type="button"
                    onClick={() => dispatch(push(`/admin/bantuan`))}
                  >
                    Cancel
                  </Button>
                </Col>
              </FormGroup>
            )}
          </Form>
        </FormGroup>
      </Widget>
      <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)}>
        <ModalHeader toggle={() => setModalOpen(false)}>
          Hasil Validasi pada Data Bantuan
        </ModalHeader>
        <ModalBody>
          {validasiBantuan ? (
            <Widget className="widget-p-md">
              Bantuan <code> {bantuan} </code> sudah pernah diinput sebelumnya.
              Data yang anda masukan tidak bisa disimpan.
            </Widget>
          ) : (
            <Widget className="widget-p-md">
              Bantuan <code> {bantuan} </code> siap diinput ke database. Klik{" "}
              <code>Lanjutkan</code> untuk simpan.
            </Widget>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            color={validasiBantuan ? "danger" : "primary"}
            onClick={() => setModalOpen(false)}
          >
            Batal
          </Button>
          {validasiBantuan ? (
            <di />
          ) : (
            <Button color="success" onClick={() => lanjutkan()}>
              Lanjutkan
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );

  return renderForm();
};

export default JenisBantuanForm;
