export const API_KALORI = "https://tulufali.rotendaokab.go.id/api/";
//'http://localhost:4000/api/admin/';

export const API_TULUFALI = "https://tulufali.rotendaokab.go.id/api/";
//'http://localhost:4000/api/admin/';

//'https://smartservice.rotendaokab.go.id/api/admin/';
//export const API_SMART = 'http://192.168.43.39:4000/api/';

export const GET_INDIVIDU_KECAMATAN = API_TULUFALI + "individu/kecamatan/";
export const GET_KELUARGA_KECAMATAN = API_TULUFALI + "keluarga/kecamatan/";

export const GET_BANTUAN_TAHUN = API_TULUFALI + "bantuantahun/ambil/tahun/";

export const GET_BANTUAN_JENIS = API_TULUFALI + "bantuan/ambil/jenis/";

export const POST_BANTUAN_JENIS = API_TULUFALI + "bantuan/simpan/jenis/";

export const POST_BANTUAN_TAHUN = API_TULUFALI + "bantuantahun/ambil";

export const DELETE_BANTUAN_TAHUN = API_TULUFALI + "bantuantahun/";

export const DELETE_BANTUAN_JENIS = API_TULUFALI + "bantuanjenis/";

export const GET_BANTUAN_TAHUN_AMBIL = API_TULUFALI + "bantuantahun/ambil/";

export const GET_BANTUAN_TAHUN_KATEGORI =
  API_TULUFALI + "databantuan/kategori/";

export const GET_BANTUAN = API_TULUFALI + "databantuan/";
export const DELETE_BANTUAN = API_TULUFALI + "databantuan/";

export const DATA_BANTUAN_API = API_TULUFALI + "databantuan/";

export const GET_INDIVIDU_DESIL_DESA = API_TULUFALI + "individu/perdesil/desa";
export const GET_INDIVIDU_DESIL_KECAMATAN =
  API_TULUFALI + "individu/perdesil/kecamatan";

export const BANTUAN_TAHUN_API = API_TULUFALI + "bantuantahun/";

export const BANTUAN_JENIS_API = API_TULUFALI + "bantuanjenis/";

export const BANTUAN_API = API_TULUFALI + "bantuan/";

export const BANTUAN_DINAS_API = API_TULUFALI + "bantuan/dinas/";

export const CARI_DATA_MISKIN_LIKE = API_TULUFALI + "datamiskin/cari/like";

export const CARI_DATA_MISKIN_LIKE_DTKS =
  API_TULUFALI + "datamiskin/cari/like/dtks";

export const CARI_NIK_DTKS = API_TULUFALI + "dtks/";

export const CARI_NIK_DUKCAPIL = API_TULUFALI + "dukcapil/";

export const GET_KECAMATAN = API_TULUFALI + "kecamatan";
export const GET_DESA = API_TULUFALI + "desa";
export const PROGRESS_BANTUAN_API = API_TULUFALI + "progresbantuan/";

export const HISTORI_BANTUAN_API = API_TULUFALI + "historibantuan/";
export const PROGRESS_DINAS_API = API_TULUFALI + "progresdinas/";

export const AKUMULASI_BANTUAN_TAHUN = API_TULUFALI + "ambil/akumulasibantuan/";

export const VALIDASI_BANTUAN = API_TULUFALI + "validasibantuan/";
export const VALIDASI_HISTORI = API_TULUFALI + "validasihistori/";
export const PIRAMIDA_PENDUDUK = API_TULUFALI + "piramidapenduduk/";
export const PEKERJAAN_PENDUDUK = API_TULUFALI + "pekerjaanpendudukmiskin/";

export const PENDIDIKAN_KECAMATAN = API_TULUFALI + "pendidikankecamatan/";
export const DESIL_KESEJAHTERAAN_KECAMATAN =
  API_TULUFALI + "desilkesejahteraankecamatan/";
export const PADAN_DUKCAPIL = API_TULUFALI + "padandukcapil/";

export const VALIDASI_INPUT_BANTUAN = API_TULUFALI + "validasi/inputbantuan/";

export const VALIDASI_INPUT_JENIS_BANTUAN =
  API_TULUFALI + "validasi/inputjenisbantuan/";

export const LIMA_TAHUN_TERAKHIR = API_TULUFALI + "bantuan/limatahunterakhir";
export const ADMIN_LOGIN = API_TULUFALI + "admin/login";
export const ADMIN_TOKEN = API_TULUFALI + "admin/token";

export const DESA_BANTUAN_TAHUN = API_TULUFALI + "bantuan/desa/";

export const LOG_APP = API_TULUFALI + "logapp/";

export const UPDATE_SK = API_TULUFALI + "bantuantahun/updateSK/";
export const UPDATE_KUNCI = API_TULUFALI + "bantuantahun/updateKunci/";

export const LIST_ADMIN_TF = API_TULUFALI + "tulufali/admin/";

export const LOGOUT_ADMIN_TF = API_TULUFALI + "admin/";

export const VALIDASI_DIFABEL = API_TULUFALI + "difabelkel/";

export const GET_UKURAN = API_TULUFALI + "ukuran";
export const GET_ASUPAN = API_TULUFALI + "asupan";
export const POST_ASUPAN = API_TULUFALI + "asupan/input";
export const GET_USER = API_TULUFALI + "user";
export const POST_USER = API_TULUFALI + "user/input";

export const POST_MAKAN = API_KALORI + "makan/input";
export const DELETE_MAKAN = API_KALORI + "makan/";
export const GET_MAKAN = API_KALORI + "makan/ambil";

export const GET_KONSUMSI_KALORI = API_KALORI + "makan/konsumsi";
export const GET_KEBUTUHAN_KALORI = API_KALORI + "kebutuhankalori";
export const GET_KONSUMSI_KALORI_KATEGORI =
  API_KALORI + "makan/konsumsi/kategori";
export const GET_KONSUMSI_KALORI_HARI = API_KALORI + "konsumsi/kalori/hari";

export const API_TIMEOUT = 120000;
