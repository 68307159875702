import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router";

import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import Breadcrumbs from "../Breadbrumbs/Breadcrumbs";

import Dashboard from "../../pages/dashboard/Dashboard";
import Profile from "../../pages/profile/Profile";
import UserListPage from "../Users/list/UsersListPage";
import JenisBantuanListPage from "../Users/list/JenisBantuanListPage";

import PenerimaListPage from "../Users/list/PenerimaListPage";
import PenerimaFormPage from "../Users/form/PenerimaFormPage";

import UserFormPage from "../Users/form/UserFormPage";

import Notifications from "../../pages/uielements/notifications/Notifications";
import Tables from "../../pages/tables/Tables";

import Navbars from "../../pages/uielements/navbar/Navbars";
import Navs from "../../pages/uielements/navs/Navs";

import BarCharts from "../../pages/charts/bar/BarCharts";
import LineCharts from "../../pages/charts/line/LineCharts";
import PieCharts from "../../pages/charts/pie/PieCharts";
import OtherCharts from "../../pages/charts/other/OtherCharts";

import Login from "../../pages/auth/login/Login";

import AsupanFormPage from "../../pages/tables/form/AsupanFormPage";

import TableProgress from "../../pages/tables/TableProgress";
import TableSK from "../../pages/tables/TableSK";

import s from "./Layout.module.scss";
import TablesKec from "../../pages/tables/TablesKec";
import TableSelesaiInput from "../../pages/tables/TableSelesaiInput";
import EvaluasiListPage from "../Users/list/EvaluasiListPage";
import TableSKSelesai from "../../pages/tables/TableSKSelesai";
import AdminListPage from "../Users/list/AdminListPage";
import TableKunci from "../../pages/tables/TableKunci";
import JenisBantuanFormPage from "../Users/form/JenisBantuanFormPage";

const Layout = (props) => {
  return (
    <div className={s.root}>
      <div className={s.wrap}>
        <Header />
        <Sidebar />
        <main className={s.content}>
          <Breadcrumbs url={props.location.pathname} />
          <Switch>
            <Route
              path="/template"
              exact
              render={() => <Redirect to="template/dashboard" />}
            />
            <Route path="/template/dashboard" exact component={Dashboard} />
            <Route
              path="/template/user"
              exact
              render={() => <Redirect to={"/template/user/profile"} />}
            />
            <Route path="/template/user/profile" exact component={Profile} />
            <Route
              path="/admin"
              exact
              render={() => <Redirect to="/admin/bantuan" />}
            />
            <Route path="/admin/bantuan" exact component={UserListPage} />
            <Route
              path="/admin/bantuan/jenis"
              exact
              component={JenisBantuanListPage}
            />
            <Route path="/admin/bantuan/new" exact component={UserFormPage} />
            <Route
              path="/admin/bantuan/jenis/new"
              exact
              component={JenisBantuanFormPage}
            />
            <Route
              path="/admin/bantuan/:id/edit"
              exact
              component={UserFormPage}
            />
            <Route
              path="/admin/bantuan/:idView"
              exact
              component={UserFormPage}
            />

            <Route
              path="/admin/bantuan/jenis/:id/edit"
              exact
              component={JenisBantuanFormPage}
            />
            <Route
              path="/admin/bantuan/jenis/:idView"
              exact
              component={JenisBantuanFormPage}
            />

            <Route path="/admin/penerima" exact component={PenerimaListPage} />
            <Route
              path="/admin/penerima/new"
              exact
              component={PenerimaFormPage}
            />
            <Route
              path="/admin/penerima/:id/edit"
              exact
              component={PenerimaFormPage}
            />
            <Route
              path="/admin/penerima/:idView"
              exact
              component={PenerimaFormPage}
            />

            <Route
              path="/template/edit_profile"
              exact
              component={UserFormPage}
            />

            <Route path="/template/desil/desa" exact component={Tables} />
            <Route
              path="/template/desil/kecamatan"
              exact
              component={TablesKec}
            />

            <Route
              path="/template/progressinput/onprogress"
              exact
              component={TableProgress}
            />
            <Route
              path="/template/progressinput/selesai"
              exact
              component={TableSelesaiInput}
            />
            <Route
              path="/template/progressSK/onprogress"
              exact
              component={TableSK}
            />
            <Route
              path="/template/progressSK/selesai"
              exact
              component={TableSKSelesai}
            />

            <Route
              path="/template/evaluasi/inputevaluasi"
              exact
              component={EvaluasiListPage}
            />
            <Route
              path="/admin/kunci/kunciaturan"
              exact
              component={TableKunci}
            />
            <Route path="/admin/listadmin" exact component={AdminListPage} />

            <Route
              path="/template/ui-elements/navbars"
              exact
              component={Navbars}
            />
            <Route path="/template/ui-elements/navs" exact component={Navs} />
            <Route
              path="/template/ui-elements/notifications"
              exact
              component={Notifications}
            />

            <Route
              path="/template/forms"
              exact
              render={() => <Redirect to={"/template/forms/elements"} />}
            />

            <Route
              path="/template/analitik"
              exact
              render={() => (
                <Redirect to={"/template/analitik/miskinekstrim"} />
              )}
            />
            <Route
              path="/template/analitik/penerima"
              exact
              component={LineCharts}
            />
            <Route
              path="/template/analitik/fasilitas"
              exact
              component={PieCharts}
            />
            <Route
              path="/template/analitik/miskinekstrim"
              exact
              component={BarCharts}
            />
            <Route
              path="/template/charts/other"
              exact
              component={OtherCharts}
            />

            <Route path="/template/extra/login" exact component={Login} />

            <Route path="*" exact render={() => <Redirect to="/error" />} />
          </Switch>
        </main>
        <Footer />
      </div>
    </div>
  );
};

Layout.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    currentUser: store.auth.currentUser,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));
