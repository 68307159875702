import React, { useEffect, useState } from 'react';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import cities from './mock';

//import roteNdaoGeo from '../../../../assets/jsonData/kab_Rote.json';
import roteNdaoGeo from '../../assets/jsonData/kab_Rote.json';

import AnimateNumber from 'react-animated-number';
import s from './am4chartMap.module.scss';
import './tooltip.css';
// Fungsi untuk menghasilkan warna acak
import { DESA_BANTUAN_TAHUN } from '../../utils';
import axios from 'axios';

const Am4chartMap = () => {
  const [listDesa, setListDesa] = useState([]);
  const features = roteNdaoGeo.features;
  const [mapCombine, setMapCombine] = useState();

  const combineData = () => {
    var desaku = [];
    var desa;
    for (let i = 0; i < features.length; i++) {
      desa = features[i];

      var bantuanDesa = listDesa.filter((item) => {
        return (
          item['desa'].toUpperCase() === desa.properties.DESA.toUpperCase()
        );
      });

      if (bantuanDesa.length > 0) {
        desa.properties.penerimaBantuan = bantuanDesa[0].jumlah_penerima;
        desa.properties.color = '#f5bf23';
      } else {
        desa.properties.penerimaBantuan = 0;
        desa.properties.color = '#8B0000';
      }

      // return [r['desa'], jmlKrr, jmlKrt, jmlKrst, ibuDesa.length, colorHamil];

      desaku.push(desa);
    }

    const datamap = {
      type: 'FeatureCollection',
      features: desaku,
    };

    setMapCombine(datamap);
  };

  function getColorByValue(value) {
    return value;
  }

  const getListDesa = async () => {
    var data1 = [];

    try {
      const response = await axios.get(DESA_BANTUAN_TAHUN + '2023');
      data1 = response.data.data;
      if (data1.length > 0) {
        setListDesa(data1);
      } else {
        setListDesa([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getListDesa();
  }, []);

  useEffect(() => {
    if (listDesa) {
      combineData();
    }
  }, [listDesa]);

  useEffect(() => {
    if (mapCombine) {
      let map = am4core.create('map', am4maps.MapChart);
      map.geodata = mapCombine;
      map.percentHeight = 90;
      map.dy = 10;
      //   map.projection = new am4maps.projections.AlbersUsa();
      let polygonSeries = map.series.push(new am4maps.MapPolygonSeries());
      polygonSeries.useGeodata = true;
      map.homeZoomLevel = 1;
      map.zoomControl = new am4maps.ZoomControl();
      map.zoomControl.layout = 'horizontal';
      map.zoomControl.align = 'left';
      map.zoomControl.valign = 'bottom';
      map.zoomControl.dy = 30;
      map.zoomControl.dx = 30;
      map.zoomControl.contentHeight = 20;
      map.zoomControl.minusButton.background.fill = am4core.color('#C7D0FF');
      map.zoomControl.minusButton.background.stroke = am4core.color('white');
      map.zoomControl.minusButton.label.fontWeight = 600;
      map.zoomControl.minusButton.label.fontSize = 22;
      map.zoomControl.minusButton.scale = 0.75;
      map.zoomControl.minusButton.label.scale = 0.75;
      map.zoomControl.plusButton.background.fill = am4core.color('#C7D0FF');
      map.zoomControl.plusButton.background.stroke = am4core.color('#6979C9');
      map.zoomControl.plusButton.label.fontWeight = 600;
      map.zoomControl.plusButton.label.fontSize = 22;
      map.zoomControl.plusButton.label.align = 'center';
      map.zoomControl.plusButton.scale = 0.75;
      map.zoomControl.plusButton.label.scale = 0.75;
      map.zoomControl.plusButton.dx = 5;
      let plusButtonHoverState =
        map.zoomControl.plusButton.background.states.create('hover');
      plusButtonHoverState.properties.fill = am4core.color('#354D84');
      let minusButtonHoverState =
        map.zoomControl.minusButton.background.states.create('hover');
      minusButtonHoverState.properties.fill = am4core.color('#354D84');
      let polygonTemplate = polygonSeries.mapPolygons.template;

      polygonTemplate.tooltipHTML = `
    <div className="custom-tooltip">
      <b>{DESA} - {KECAMATAN}</b>
      <br>Penerima bantuan sosial: {penerimaBantuan}
    </div>
    `;

      //

      //polygonTemplate.propertyFields.fill = 'SYMB';
      polygonSeries.events.on('datavalidated', () => {
        polygonSeries.mapPolygons.each((polygon) => {
          let color = polygon.dataItem.dataContext.color;
          polygon.fill = color;
        });
      });

      //

      //polygonTemplate.fill = am4core.color('red');
      polygonTemplate.stroke = am4core.color('white');
      let hs = polygonTemplate.states.create('hover');
      hs.properties.fill = am4core.color('blue');

      //

      //

      let citySeries = map.series.push(new am4maps.MapImageSeries());
      citySeries.data = cities;
      citySeries.dataFields.value = 'size';
      let city = citySeries.mapImages.template;
      city.nonScaling = true;
      city.propertyFields.latitude = 'latitude';
      city.propertyFields.longitude = 'longitude';
      let circle = city.createChild(am4core.Circle);
      circle.fill = am4core.color('#C7D0FF');
      circle.strokeWidth = 0;
      let circleHoverState = circle.states.create('hover');
      circleHoverState.properties.strokeWidth = 1;
      circle.tooltipText = '{tooltip}';
      circle.propertyFields.radius = 'size';
      // return () => {
      //   if (map) {
      //     map.dispose();
      //   }
      // };
    }
  }, [mapCombine]);

  return (
    <div className={s.mapChart}>
      <div className={s.stats}>
        <h6 className='mt-1'>GEO-LOCATIONS</h6>
        <p className='h3 m-0'>
          <span className='mr-xs fw-normal'>
            <AnimateNumber
              value={123555}
              initialValue={0}
              duration={1000}
              stepPrecision={0}
              formatValue={(n) =>
                n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
              }
            />
          </span>
          <i className='fa fa-map-marker' />
        </p>
      </div>

      <div className={s.map} id='map'></div>
    </div>
  );
};

export default Am4chartMap;
