import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import * as dataFormat from "./UsersDataFormatters";
import actions from "../../../actions/usersListActions";
import axios from "axios";
import {
  GET_USER,
  API_TIMEOUT,
  GET_BANTUAN_JENIS,
  DELETE_BANTUAN_JENIS,
} from "../../../utils";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  DropdownToggle,
  Badge,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

import Widget from "../../Widget/Widget";

import s from "../Users.module.scss";
import { useDispatch } from "react-redux";
import { numberWithCommas } from "../../../utils/func";
import { logApp } from "../../../utils/func";
import { DELETE_BANTUAN_TAHUN } from "../../../utils";
import { set } from "lodash";

const { SearchBar } = Search;

const JenisBantuanListTable = (props) => {
  const { rows, dispatch, loading, idToDelete, modalOpen } = props;
  const [tableWidth, setTableWidth] = useState(window.innerWidth);
  const [rowsTable, setRowsTable] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [rowsKu, setRowsKu] = useState([]);
  const [idHapus, setIdHapus] = useState();
  const [simpleDropdownValue2, setSimpleDropdownValue2] = useState("2023");
  const [jumlahBantuan, setJumlahBantuan] = useState(0);

  const [tahun, setTahun] = useState("2023");

  const [modalDelete, setModalDelete] = useState(false);

  const dispatchKu = useDispatch();

  const cariBantuanTahun = (tahun) => {
    axios({
      method: "post",
      url: GET_BANTUAN_JENIS,
      timeout: API_TIMEOUT,
      data: {
        tahun: tahun,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          let dataFilter = [];

          if (currentUser.peran == "admin") {
            dataFilter = data.filter((item) => {
              return item.dinas == currentUser.dinas;
            });
          } else {
            dataFilter = data;
          }

          setJumlahBantuan(dataFilter.length);
          setRowsKu(dataFilter);

          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  useEffect(() => {
    if (!currentUser) {
      let currentUser = JSON.parse(localStorage.getItem("user"));
      //untuk kepentngan development

      setCurrentUser(currentUser);
      logApp(currentUser.uid, "Daftar Bantuan");
    }
  }, []);

  useEffect(() => {
    const uid = currentUser ? currentUser.uid : null;
    if (uid) cariBantuanTahun("2023");
  }, [currentUser]);

  const hitungUmur = (dob) => {
    const startDate = new Date(dob);
    const today = new Date();

    const yearDiff = today.getFullYear() - startDate.getFullYear();
    const monthDiff = today.getMonth() - startDate.getMonth();
    const totalMonthDiff = yearDiff * 12 + monthDiff;
    return totalMonthDiff;
  };

  const handleDelete = () => {
    axios({
      method: "delete",
      url: DELETE_BANTUAN_JENIS + idHapus,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          alert("Data bantuan berhasil dihapus");
          const idToDelete = idHapus;
          const filteredArray = rowsKu.filter((obj) => obj.id !== idToDelete);
          setRowsKu(filteredArray);
          setModalDelete(false);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };
  const openModal = (cell) => {
    const id = cell;

    setIdHapus(id);
    setModalDelete(true);
  };
  const closeModal = () => {
    setModalDelete(false);
  };
  const actionFormatter = (cell) => {
    return (
      <div className={`d-flex justify-content-between`}>
        <Button
          className={s.controlBtn}
          color="info"
          size="xs"
          onClick={() => props.dispatch(push(`/admin/bantuan/jenis/${cell}`))}
        >
          View
        </Button>
        <Button
          className={`${s.controlBtn} mx-2`}
          color="success"
          size="xs"
          onClick={() =>
            props.dispatch(push(`/admin/bantuan/jenis/${cell}/edit`))
          }
        >
          Edit
        </Button>
        <Button
          className={s.controlBtn}
          color="danger"
          size="xs"
          onClick={() => {
            openModal(cell);
          }}
        >
          Delete
        </Button>
      </div>
    );
  };

  const updateWindowDimensions = () => {
    setTableWidth(window.innerWidth);
  };

  useEffect(() => {
    dispatch(actions.doFetch({}));
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  const columns = [
    {
      dataField: "dinas",
      sort: true,

      text: "Dinas",
    },
    {
      dataField: "bantuan",
      sort: true,
      text: "Bantuan",
    },
    {
      dataField: "sumberDana",
      sort: true,
      text: "Sumber Dana",
    },
    {
      dataField: "bentuk",
      sort: true,
      text: "Bentuk",
    },
    {
      dataField: "target",
      sort: true,
      text: "target",
    },
    {
      dataField: "jenis",
      sort: true,
      text: "Jenis",
    },

    {
      dataField: "id",
      formatter: actionFormatter,
      text: "Actions",
    },
  ];

  return (
    <div>
      <Widget className="widget-p-md">
        <Row>
          <Col
            className="pr-grid-col"
            xs={4}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <code> Jumlah bantuan </code>{" "}
            <Badge color="danger">{jumlahBantuan}</Badge>
          </Col>

          <Col
            className="pr-grid-col"
            style={{
              display: "flex",

              justifyContent: "flex-end",
            }}
          >
            <Button
              color="success"
              onClick={() => dispatchKu(push(`/admin/bantuan/jenis/new`))}
            >
              Tambah Bantuan
            </Button>
          </Col>
        </Row>

        <ToolkitProvider columns={columns} data={rowsKu} keyField="id" search>
          {(props) => (
            <React.Fragment>
              <BootstrapTable
                bordered={false}
                classes={`table-striped table-hover mt-4 ${
                  tableWidth < 1150 ? "table-responsive" : ""
                }`}
                pagination={paginationFactory()}
                {...props.baseProps}
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
      </Widget>
      <Modal size="sm" isOpen={modalDelete} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>Konfirmasi hapus</ModalHeader>
        <ModalBody>
          apakah anda yakin akan menghapus data bantuan ini?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => handleDelete()}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    loading: store.users.list.loading,
    rows: store.users.list.rows,
    modalOpen: store.users.list.modalOpen,
    idToDelete: store.users.list.idToDelete,
  };
}

export default connect(mapStateToProps)(JenisBantuanListTable);
