import React, { Component, useEffect, useState } from "react";
import { Form } from "reactstrap";
import CustomModal from "../../CustomModal/CustomModal";
import { Alert } from "reactstrap";
import cx from "classnames";
import UsersListTable from "./UsersListTable";

import s from "../Users.module.scss";
import JenisBantuanListTable from "./JenisBantuanListTable";

const JenisBantuanListPage = () => {
  const [promoAlert, setPromoAlert] = useState(false);

  const showPromoAlert = () => setPromoAlert(true);

  useEffect(() => {
    setTimeout(() => {
      showPromoAlert();
    }, 100);
  });

  return (
    <div>
      <div className="page-top-line">
        <h2 className="page-title">
          Manajemen <span className="fw-semi-bold">Bantuan</span>
        </h2>
        <Alert
          color="primary"
          className={cx(s.promoAlert, { [s.showAlert]: promoAlert })}
        >
          Aplikasi ini terintegrasi ke{" "}
          <a
            className="text-white font-weight-bold"
            rel="noreferrer noopener"
            href=""
            target="_blank"
          >
            Rote Smart Service
          </a>{" "}
          integration!
        </Alert>
      </div>
      <JenisBantuanListTable />
    </div>
  );
};

export default JenisBantuanListPage;
