import React, { useState, useEffect } from "react";

import {
  Col,
  Row,
  Progress,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";

import "eva-icons/style/eva-icons.css";

import ApexCharts from "react-apexcharts";

import gymIcon from "../../assets/dashboard/gymIcon.svg";
import therapyIcon from "../../assets/dashboard/therapyIcon.svg";

import jwt_decode from "jwt-decode";
import swal from "sweetalert";
import { logApp } from "../../utils/func.js";

import axios from "axios";
import {
  API_TIMEOUT,
  GET_INDIVIDU_KECAMATAN,
  GET_KELUARGA_KECAMATAN,
  PROGRESS_DINAS_API,
  PEKERJAAN_PENDUDUK,
  LIMA_TAHUN_TERAKHIR,
  ADMIN_TOKEN,
} from "../../utils/index.js";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import s from "./Dashboard.module.scss";

import Map from "../../components/am4chartMap/am4chartMap.js";

import { logoutUser } from "../../actions/auth.js";
import { useDispatch } from "react-redux";

export default function Dashboard(props) {
  const [checkboxes, setCheckboxes] = useState([true, false]);

  const [currentUser, setCurrentUser] = useState(null);

  const [simpleDropdownValue, setSimpleDropdownValue] = useState("2023");

  const [progresDinas, setProgresDinas] = useState([]);

  const [expire, setExpire] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const dispatch = useDispatch();
  const doLogout = () => {
    dispatch(logoutUser());
  };
  const refreshToken = async () => {
    try {
      const response = await axios.get(ADMIN_TOKEN, {
        credentials: "include",
      });
      const decoded = jwt_decode(response.data.accessToken);

      setAccessToken(response.data.accessToken);

      setExpire(decoded.exp);
    } catch (error) {
      doLogout();
    }
  };

  const [listPekerjaanPenduduk, setListPekerjaanPenduduk] = useState({
    label: [],
    penduduk: [],
  });

  const [limaTahun, setLimaTahun] = useState({ label: [], data: [] });

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(ADMIN_TOKEN);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;

        const decoded = jwt_decode(response.data.accessToken);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const highcharts = {
    variableRadiusPie: {
      chart: {
        type: "variablepie",
      },
      title: {
        text: "Pekerjaan KK Miskin Ekstrim",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        headerFormat: "",
        pointFormat:
          '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
          "Jenis Pekerjaan: <b>{point.y}</b><br/>",
      },
      colors: [
        "#16365f",
        "#41D5E2",
        "#FFC405",
        "#43BC13",
        "#FF5668",
        "#C7D0D9",
        "#00A5FF",
      ],
      series: [
        {
          minPointSize: 10,
          innerSize: "20%",
          zMin: 0,
          name: "countries",
          data: listPekerjaanPenduduk,
        },
      ],
    },
  };

  //auth
  const pekerjaanPenduduk = () => {
    axios({
      method: "get",
      url: PEKERJAAN_PENDUDUK,
      timeout: API_TIMEOUT,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          const label = data.map((item) => {
            return item.pekerjaan;
          });

          const penduduk = data.map((item) => {
            return item.jumlah_penduduk;
          });

          let array = [];

          for (let i = 0; i < 6; i++) {
            if (label[i] != "Petani") {
              const a = {
                name: label[i],
                y: penduduk[i],
                z: Math.floor(Math.random() * 3) + 1,
              };
              array.push(a);
            } else {
              const a = {
                name: label[i],
                y: Math.round(penduduk[i]),
                z: Math.floor(Math.random() * 3) + 1,
              };
              array.push(a);
            }
          }

          setListPekerjaanPenduduk(array);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  //auth
  const limaTahunTerakhir = () => {
    axios({
      method: "get",
      url: LIMA_TAHUN_TERAKHIR,
      timeout: API_TIMEOUT,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          const label = data.map((item) => {
            return item.tahun;
          });

          const arr = ["total_ME", "total_penerima"];

          const totalME = data.map((item) => {
            return item.total_ME;
          });

          const totaPenerima = data.map((item) => {
            return item.total_penerima;
          });
          const dataKu = [
            {
              name: "Miskin Ekstrim",
              data: totalME,
            },
            {
              name: "Total Penerima",
              data: totaPenerima,
            },
          ];
          setLimaTahun({
            label: label,
            data: dataKu,
          });
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const [kecamatan, setKecamatan] = useState([
    "Landu",
    "Loaho",
    "Lobal",
    "Ndao",
    "Panbar",
    "Rosel",
    "Robar",
    "RBD",
    "RBL",
    "Roteng",
    "Rotim",
  ]);
  const [jmlOrang, setJmlOrang] = useState([]);
  const [jmlKeluarga, setJmlKeluarga] = useState([]);

  let satMin = [];

  const changeSelectDropdownSimple = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue(e.currentTarget.textContent);
    const curTahun = listTahun.filter((item) => {
      return item.tahun == select;
    });
    getProgresDinas(curTahun[0].tahun);
  };

  const apexCharts = {
    lineColumn: {
      series: [
        {
          name: "Miskin Ekstrim",
          type: "column",
          data: jmlOrang,
        },
        {
          name: "Penduduk",
          type: "line",
          data: jmlKeluarga,
        },
      ],
      options: {
        colors: ["#C7D0D9", "#FF5668"],
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "straight",
          width: [0, 1],
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
          style: {
            fontSize: "10px",
            fontWeight: 500,
          },
          background: {
            borderWidth: 0,
          },
        },
        labels: kecamatan,
        xaxis: {
          type: "category",
          labels: {
            style: {
              colors: "#6B859E",
            },
          },
        },
        yaxis: [
          {
            title: {
              text: "penduduk / kk",
              style: {
                fontSize: "12px",
                fontWeight: 400,
                color: "#6B859E",
              },
            },
            labels: {
              style: {
                colors: ["#6B859E"],
              },
            },
          },
        ],
        fill: {
          type: "solid",
          opacity: 1,
        },
      },
    },
    lineArea: {
      series: limaTahun.data,
      options: {
        chart: {
          type: "area",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        xaxis: {
          type: "category",
          categories: limaTahun.label,
          labels: {
            style: {
              colors: "#6B859E",
              opacity: 0.7,
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: ["#6B859E"],
              opacity: 0.7,
            },
          },
        },
        tooltip: {
          x: {
            show: false,
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 1,
            stops: [40, 90, 100],
          },
        },
        colors: ["#4D53E0", "#41D5E2"],
        legend: {
          show: true,
          horizontalAlign: "center",
        },
      },
    },
  };

  const toggleCheckbox = (id) => {
    setCheckboxes((checkboxes) =>
      checkboxes.map((checkbox, index) => (index === id ? !checkbox : checkbox))
    );
  };

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      setCurrentUser(currentUser);
      logApp(currentUser.uid, "Dashboard");
    }

    refreshToken();

    // getDataMiskinKecamatan();
    // getDataMiskinKeluargaKecamatan();
    // getProgresDinas('2023');
    // pekerjaanPenduduk();
    // limaTahunTerakhir();
  }, []);

  useEffect(() => {
    if (accessToken) {
      getDataMiskinKecamatan();
      getDataMiskinKeluargaKecamatan();
      getProgresDinas("2023");
      pekerjaanPenduduk();
      limaTahunTerakhir();
    }
  }, [accessToken]);

  const listTahun = [
    { tahun: 2018 },
    { tahun: 2019 },
    { tahun: 2020 },
    { tahun: 2021 },
    { tahun: 2022 },
    { tahun: 2023 },
    { tahun: 2024 },
    { tahun: 2025 },
    { tahun: 2026 },
  ];

  //auth
  const getDataMiskinKecamatan = () => {
    axios({
      method: "get",
      url: GET_INDIVIDU_KECAMATAN,
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          const hasil = response.data.data;

          const jml = hasil.map((item) => {
            return item.jumlah_orang;
          });

          setJmlOrang(jml);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const toSmallCaps = (text) => {
    return text.toLowerCase().replace(/(?:^|\s)\S/g, (char) => {
      return char.toUpperCase();
    });
  };

  //auth

  const getProgresDinas = (tahun) => {
    axios({
      method: "get",
      url: PROGRESS_DINAS_API + tahun,
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          const data = response.data.data;

          const groupedData = data.reduce((result, item) => {
            const { dinas, jmlPenerima, jumlahmiskinekstrim, jumlahpenerima } =
              item;
            if (!result[dinas]) {
              result[dinas] = {
                dinas,
                totalPenerima: 0,
                totalME: 0,
                penerima: 0,
              };
            }
            result[dinas].totalPenerima += parseInt(jmlPenerima);
            result[dinas].totalME += jumlahmiskinekstrim;
            result[dinas].penerima += jumlahpenerima;
            return result;
          }, {});

          const groupedArray = Object.values(groupedData);

          setProgresDinas(groupedArray);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  //auth
  const getDataMiskinKeluargaKecamatan = () => {
    axios({
      method: "get",
      url: GET_KELUARGA_KECAMATAN,
      timeout: API_TIMEOUT,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          const hasil = response.data.data;
          const jml = hasil.map((item) => {
            return item.jumlah_keluarga;
          });

          setJmlKeluarga(jml);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  return (
    <div>
      <Row>
        <Col className="pr-grid-col" xs={12} lg={8}>
          <Row className="gutter mb-4">
            <Col xs={12}>
              <Widget style={{ padding: 20 }}>
                <div className="headline-2 mb-2">
                  Individu vs keluarga Miskin Ekstrin
                </div>
                <ApexCharts
                  series={apexCharts.lineColumn.series}
                  options={apexCharts.lineColumn.options}
                />
              </Widget>
            </Col>
          </Row>
          <Row className="gutter">
            <Col xs={12}>
              <Widget style={{ padding: 20 }}>
                <div className="headline-2 mb-2">
                  Penerima Bantuan 5 Tahun Terakhir
                </div>
                <ApexCharts
                  series={apexCharts.lineArea.series}
                  options={apexCharts.lineArea.options}
                  type="area"
                />
              </Widget>
            </Col>
          </Row>
          <Row className="gutter">
            <Col xs={12}>
              <Widget>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={highcharts.variableRadiusPie}
                />
              </Widget>
            </Col>
          </Row>
        </Col>
        <Col className="mt-4 mt-lg-0 pl-grid-col" xs={12} lg={4}>
          <Widget className="widget-p-lg">
            <div className={s.goalsTitle}>
              <p className="headline-3">Bansos</p>
              <UncontrolledButtonDropdown>
                <DropdownToggle caret color="primary">
                  {simpleDropdownValue}
                </DropdownToggle>

                <DropdownMenu>
                  {listTahun &&
                    listTahun.map((item) => (
                      <DropdownItem onClick={changeSelectDropdownSimple}>
                        {item.tahun}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            {progresDinas ? (
              progresDinas.map((item) => (
                <div className="d-flex flex-column mt-3">
                  <div className={s.activity}>
                    <p className="body-2">{toSmallCaps(item.dinas)} </p>
                    <p className="body-2">
                      {item.penerima}
                      <span className="body-3 muted">
                        /{item.totalPenerima}
                      </span>
                    </p>
                  </div>
                  <Progress
                    color={
                      (item.penerima / item.totalPenerima) * 100 < 50
                        ? "danger"
                        : (item.penerima / item.totalPenerima) * 100 >= 50 &&
                          (item.penerima / item.totalPenerima) * 100 <= 90
                        ? "warning"
                        : "success"
                    }
                    className="progress-xs"
                    value={(item.penerima / item.totalPenerima) * 100}
                  />
                </div>
              ))
            ) : (
              <div />
            )}

            <p className="headline-3">Konseling</p>
            <div className={`mt-3 ${s.widgetBlock}`}>
              <div className={s.widgetBody}>
                <div className="d-flex">
                  <img className="img-fluid mr-2" src={gymIcon} alt="..." />
                  <div className="d-flex flex-column">
                    <p className="body-2">Langsung</p>
                    <p className="body-3 muted">Pengembalian</p>
                  </div>
                </div>
                <div className="checkbox checkbox-primary">
                  <input
                    id="checkbox0"
                    type="checkbox"
                    className="styled"
                    checked={checkboxes[0]}
                    onChange={(event) => toggleCheckbox(0)}
                  />
                  <label htmlFor="checkbox0" />
                </div>
              </div>
            </div>
            <div className={`mt-3 ${s.widgetBlock}`}>
              <div className={s.widgetBody}>
                <div className="d-flex">
                  <img className="img-fluid mr-2" src={therapyIcon} alt="..." />
                  <div className="d-flex flex-column">
                    <p className="body-2">Hibah</p>
                    <p className="body-3 muted">Bansos</p>
                  </div>
                </div>
                <div className="checkbox checkbox-primary">
                  <input
                    id="checkbox1"
                    type="checkbox"
                    className="styled"
                    checked={checkboxes[1]}
                    onChange={(event) => toggleCheckbox(1)}
                  />
                  <label htmlFor="checkbox1" />
                </div>
              </div>
            </div>
            <a
              className={`btn-secondary-red ${s.statsBtn}`}
              href="#top"
              role="button"
            >
              <div style={{ marginRight: 10 }}>
                <i className="eva eva-phone" />
              </div>
              <div>
                <p className="headline-2">KONSULTASI</p>
                <p className="body-3">Kirim pertayaan konsultasi</p>
              </div>
            </a>
          </Widget>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col className="pr-grid-col" xs={12} lg={12}>
          <Widget style={{ padding: 20 }}>
            <Map />
          </Widget>
        </Col>
      </Row>
    </div>
  );
}
