import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import ApexCharts from 'react-apexcharts';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartMore from 'highcharts/highcharts-more';
import Widget from '../../../components/Widget/Widget';
import s from './BarCharts.module.scss';
import chartsData from './mock';

import {
  PIRAMIDA_PENDUDUK,
  API_TIMEOUT,
  PEKERJAAN_PENDUDUK,
  PENDIDIKAN_KECAMATAN,
  DESIL_KESEJAHTERAAN_KECAMATAN,
  PADAN_DUKCAPIL,
} from '../../../utils';
import axios from 'axios';
import { logApp } from '../../../utils/func';

HighchartMore(Highcharts);

export default function BarCharts() {
  const [listPiramidaPenduduk, setListPiramidaPenduduk] = useState({
    label: [],
    laki: [],
    perempuan: [],
  });

  const [listPekerjaanPenduduk, setListPekerjaanPenduduk] = useState({
    label: [],
    penduduk: [],
  });
  const [labelStack, setLabelStack] = useState([]);
  const [dataStack, setDataStack] = useState([]);
  const [labelStack2, setLabelStack2] = useState([]);
  const [dataStack2, setDataStack2] = useState([]);

  const [labelPadan, setLabelPadan] = useState([]);
  const [dataPadan, setDataPadan] = useState([]);

  const [activeTab, setActiveTab] = useState(1);
  const { recharts, highcharts } = chartsData;

  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      setCurrentUser(currentUser);
      logApp(currentUser.uid, 'Analitik');
    }
  }, []);

  const piramidaPenduduk = () => {
    axios({
      method: 'get',
      url: PIRAMIDA_PENDUDUK,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;

          const label = data.map((item) => {
            return item.kelompok_usia;
          });

          const laki = data.map((item) => {
            return item.jumlah_laki_laki;
          });
          const perempuan = data.map((item) => {
            return item.jumlah_perempuan * -1;
          });

          setListPiramidaPenduduk({
            label: label.reverse(),
            laki: laki.reverse(),
            perempuan: perempuan.reverse(),
          });
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const pekerjaanPenduduk = () => {
    axios({
      method: 'get',
      url: PEKERJAAN_PENDUDUK,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;

          const label = data.map((item) => {
            return item.pekerjaan;
          });

          const penduduk = data.map((item) => {
            return item.jumlah_penduduk;
          });

          setListPekerjaanPenduduk({
            label: label.reverse(),
            penduduk: penduduk.reverse(),
          });
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const toSmallCaps = (text) => {
    return text.toLowerCase().replace(/(?:^|\s)\S/g, (char) => {
      return char.toUpperCase();
    });
  };

  const pendidikankecamatan = () => {
    axios({
      method: 'get',
      url: PENDIDIKAN_KECAMATAN,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;

          const label = data.map((item) => {
            return toSmallCaps(item.kecamatan);
          });
          const labelOk = [...new Set(label)];

          const pend = [
            'Tidak/belum sekolah',
            'Siswa SD/sederajat',
            'Tidak tamat SD/sederajat',
            'Tamat SD/sederajat',
            'Siswa SMP/sederajat',
            'Tamat SMP/sederajat',
            'Siswa SMA/sederajat',
            'Tamat SMA/sederajat',
            'Mahasiswa Perguruan Tinggi',
            'Tamat Perguruan Tinggi',
          ];

          const pendFix = pend.map((item) => {
            const fil = data.filter((item2) => {
              return item === item2.pendidikan;
            });

            const pendKec = labelOk.map((item3) => {
              const fil2 = fil.filter((item4) => {
                return item3 === toSmallCaps(item4.kecamatan);
              });
              let dd = 0;

              if (fil2.length > 0) dd = fil2[0].jumlah_penduduk;
              return dd;
            });

            return { name: item, data: pendKec };
          });
          setLabelStack(labelOk);
          setDataStack(pendFix);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const desilKesejahteraan = () => {
    axios({
      method: 'get',
      url: DESIL_KESEJAHTERAAN_KECAMATAN,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;

          const label = data.map((item) => {
            return toSmallCaps(item.kecamatan);
          });
          const labelOk = [...new Set(label)];

          const dekes = [1, 2, 3];

          const dekesFix = dekes.map((item) => {
            const fil = data.filter((item2) => {
              return item === item2.desilKesejahteraan;
            });

            const desilKec = labelOk.map((item3) => {
              const fil2 = fil.filter((item4) => {
                return item3 === toSmallCaps(item4.kecamatan);
              });
              let dd = 0;

              if (fil2.length > 0) dd = fil2[0].jumlah_penduduk;
              return dd;
            });

            return { name: item, data: desilKec };
          });
          setLabelStack2(labelOk);
          setDataStack2(dekesFix);

          console.log(labelOk);
          console.log(dekesFix);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const padanDukcapil = () => {
    axios({
      method: 'get',
      url: PADAN_DUKCAPIL,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;

          const label = data.map((item) => {
            return toSmallCaps(item.kecamatan);
          });
          const labelOk = [...new Set(label)];

          const padan = ['Ya', 'Tidak'];

          const padanFix = padan.map((item) => {
            const fil = data.filter((item2) => {
              return item === item2.padanDukcapil;
            });

            const padanKec = labelOk.map((item3) => {
              const fil2 = fil.filter((item4) => {
                return item3 === toSmallCaps(item4.kecamatan);
              });
              let dd = 0;

              if (fil2.length > 0) dd = fil2[0].jumlah_penduduk;
              return dd;
            });

            return { name: item, data: padanKec };
          });
          setLabelPadan(labelOk);
          setDataPadan(padanFix);

          console.log(labelOk);
          console.log(padanFix);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  useEffect(() => {
    piramidaPenduduk();
    pekerjaanPenduduk();
    pendidikankecamatan();
    desilKesejahteraan();
    padanDukcapil();
  }, []);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const apexCharts = {
    basicBar: {
      series: [
        {
          data: listPekerjaanPenduduk.penduduk,
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        title: {
          text: 'Pekerjaan Kepala Keluarga Miskin Ekstrim',
          align: 'center',
        },
        colors: ['#4d53e0'],
        plotOptions: {
          bar: {
            borderRadius: 8,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: listPekerjaanPenduduk.label,
          labels: {
            style: {
              colors: '#6B859E',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: '#6B859E',
            },
          },
        },
      },
    },
    stackedBar: {
      series: dataStack,
      options: {
        chart: {
          type: 'bar',
          height: 550,
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        title: {
          text: 'Pendidikan KK Miskin Ektrim',
          align: 'center',
        },
        colors: [
          '#00A5FF',
          '#43BC13',
          '#FFC405',
          '#FF5668',
          '#4d53e0',
          '#4d23e0',
          '#4373a0',
          '#4d73e0',
          '#FF5118',
          '#FF2168',
        ],
        xaxis: {
          categories: labelStack,
          labels: {
            formatter: function (val) {
              return val;
            },
            style: {
              colors: '#6B859E',
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
            align: 'center',
          },
          labels: {
            style: {
              colors: '#6B859E',
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 10,
        },
      },
    },
    stackedBar2: {
      series: dataStack2,
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        title: {
          text: 'Desil 1, 2, 3 Per Kecamantan',
          align: 'center',
        },
        colors: ['#00A5FF', '#43BC13', '#FFC405'],
        xaxis: {
          categories: labelStack2,
          labels: {
            formatter: function (val) {
              return val;
            },
            style: {
              colors: '#6B859E',
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
            align: 'center',
          },
          labels: {
            style: {
              colors: '#6B859E',
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40,
        },
      },
    },
    negativeValuesBar: {
      series: [
        {
          name: 'Males',
          data: listPiramidaPenduduk.laki,
        },
        {
          name: 'Females',
          data: listPiramidaPenduduk.perempuan,
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 440,
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        colors: ['#00A5FF', '#FF5668'],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '80%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },

        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        yaxis: {
          min: -6000,
          max: 6000,
          title: {
            // text: 'Age',
          },
          labels: {
            style: {
              colors: '#6B859E',
            },
          },
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function (val) {
              return val;
            },
          },
          y: {
            formatter: function (val) {
              return Math.abs(val);
            },
          },
        },
        title: {
          text: 'Populasi Miskin Ekstrim 2023',
          align: 'center',
        },
        xaxis: {
          categories: listPiramidaPenduduk.label,
          labels: {
            formatter: function (val) {
              return Math.abs(Math.round(val));
            },
            style: {
              colors: '#6B859E',
            },
          },
        },
      },
    },
    groupedBar: {
      series: dataPadan,
      options: {
        chart: {
          type: 'bar',
          height: 530,
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        colors: ['#6b859e', '#FFA100'],
        title: {
          text: 'Data NIK padan Dukcapil',
          align: 'center',
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 2,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: '10px',
            colors: ['#fff'],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: labelPadan,
          labels: {
            style: {
              colors: '#6B859E',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: '#6B859E',
            },
          },
        },
      },
    },
  };

  return (
    <Widget className='charts-tabs-widget' style={{ overflow: 'auto' }}>
      <Nav tabs className='mb-5'>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 1 })}
            onClick={() => toggleTab(1)}
          >
            <div className='headline-3'>Apex Charts</div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 2 })}
            onClick={() => toggleTab(2)}
          >
            <div className='headline-3'>Recharts</div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 3 })}
            onClick={() => toggleTab(3)}
          >
            <div className='headline-3'>Highcharts</div>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Col>
            <Row className='mb-4'>
              <Col xs={12} lg={6}>
                <Widget>
                  <ApexCharts
                    type='bar'
                    options={apexCharts.basicBar.options}
                    series={apexCharts.basicBar.series}
                  />
                </Widget>
              </Col>
              <Col xs={12} lg={6}>
                <Widget>
                  <ApexCharts
                    type='bar'
                    options={apexCharts.stackedBar2.options}
                    series={apexCharts.stackedBar2.series}
                  />
                </Widget>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <Widget>
                  <ApexCharts
                    type='bar'
                    options={apexCharts.negativeValuesBar.options}
                    series={apexCharts.negativeValuesBar.series}
                  />
                </Widget>
              </Col>
              <Col xs={12} lg={6}>
                <Widget>
                  <ApexCharts
                    type='bar'
                    options={apexCharts.groupedBar.options}
                    series={apexCharts.groupedBar.series}
                  />
                </Widget>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={8}>
                <Widget>
                  <ApexCharts
                    type='bar'
                    options={apexCharts.stackedBar.options}
                    series={apexCharts.stackedBar.series}
                  />
                </Widget>
              </Col>
            </Row>
          </Col>
        </TabPane>
        <TabPane tabId={2}>
          <Col>
            <Row className='mb-4'>
              <Col xs={12} lg={6}>
                <Widget>
                  <div className='headline-2 mb-2'>Simple Bar Chart</div>
                  <div className={s.rechartsBlock}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <BarChart
                        width={500}
                        height={350}
                        data={recharts.simpleBar.data}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='name' />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey='pv' fill='#4d53e0' />
                        <Bar dataKey='uv' fill='#6b859e' />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </Widget>
              </Col>
              <Col xs={12} lg={6}>
                <Widget>
                  <div className='headline-2 mb-2'>Mix Bar Chart</div>
                  <div className={s.rechartsBlock}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <BarChart
                        width={500}
                        height={350}
                        data={recharts.mixBarChart.data}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='name' />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey='pv' stackId='a' fill='#2F33A7' />
                        <Bar dataKey='amt' stackId='a' fill='#FFC405' />
                        <Bar dataKey='uv' fill='#FF5668' />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </Widget>
              </Col>
            </Row>
            <Row className='mb-4'>
              <Col xs={12} lg={6}>
                <Widget>
                  <div className='headline-2 mb-2'>Stacked Bar Chart</div>
                  <div className={s.rechartsBlock}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <BarChart
                        width={500}
                        height={350}
                        data={recharts.stackedChart.data}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='name' />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey='pv' stackId='a' fill='#FF4B23' />
                        <Bar dataKey='uv' stackId='a' fill='#C7D0D9' />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </Widget>
              </Col>
              <Col xs={12} lg={6}>
                <Widget>
                  <div className='headline-2 mb-2'>
                    Positive and Negative Bar Chart
                  </div>
                  <div className={s.rechartsBlock}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <BarChart
                        width={500}
                        height={350}
                        data={recharts.positiveAndNegativeChart.data}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='name' />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <ReferenceLine y={0} stroke='#000' />
                        <Bar dataKey='pv' fill='#FFA100' />
                        <Bar dataKey='uv' fill='#4d53e0' />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </Widget>
              </Col>
            </Row>
          </Col>
        </TabPane>
        <TabPane tabId={3}>
          <Col>
            <Row className='mb-4'>
              <Col xs={12} lg={6}>
                <Widget>
                  <div className='headline-2 mb-2'>Basic Bar Chart</div>
                  <div className={s.rechartsBlock}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={highcharts.basicBar}
                    />
                  </div>
                </Widget>
              </Col>
              <Col xs={12} lg={6}>
                <Widget>
                  <div className='headline-2 mb-2'>Basic Column Chart</div>
                  <div className={s.rechartsBlock}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={highcharts.basicColumn}
                    />
                  </div>
                </Widget>
              </Col>
            </Row>
            <Row className='mb-4'>
              <Col xs={12} lg={6}>
                <Widget>
                  <div className='headline-2 mb-2'>
                    Chart with Negative Stack
                  </div>
                  <div className={s.rechartsBlock}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={highcharts.negativeStack}
                    />
                  </div>
                </Widget>
              </Col>
              <Col xs={12} lg={6}>
                <Widget>
                  <div className='headline-2 mb-2'>Column Range Chart</div>
                  <div className={s.rechartsBlock}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={highcharts.rangeChart}
                    />
                  </div>
                </Widget>
              </Col>
            </Row>
          </Col>
        </TabPane>
      </TabContent>
    </Widget>
  );
}
