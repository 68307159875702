import React from 'react';
import { logoutUser } from './actions/auth';
import { Redirect, Route } from 'react-router';
import hasToken from './services/authService';
// simpan tahannnnnnnnnnnn

// export const AdminRoute = ({ currentUser, dispatch, component, ...rest }) => {
//   if (!currentUser || currentUser.status !== 'admin') {
//     return <Redirect to='/template' />;
//   } else if (currentUser && currentUser.status === 'admin') {
//     return (
//       <Route
//         {...rest}
//         render={(props) => React.createElement(component, props)}
//       />
//     );
//   }
// };

export const AdminRoute = ({ currentUser, dispatch, component, ...rest }) => {
  if (!currentUser) {
    return <Redirect to='/template' />;
  } else {
    return (
      <Route
        {...rest}
        render={(props) => React.createElement(component, props)}
      />
    );
  }
};

export const UserRoute = ({ dispatch, component, currentUser, ...rest }) => {
  if (!currentUser) {
    dispatch(logoutUser());
    return <Redirect to='/login' />;
  } else {
    return (
      <Route
        {...rest}
        render={(props) => React.createElement(component, props)}
      />
    );
  }
};

export const AuthRoute = ({ dispatch, component, ...rest }) => {
  const { from } = rest.location.state || { from: { pathname: '/template' } };

  if (hasToken()) {
    return <Redirect to={from} />;
  } else {
    return (
      <Route
        {...rest}
        render={(props) => React.createElement(component, props)}
      />
    );
  }
};
