import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import * as dataFormat from "./UsersDataFormatters";
import actions from "../../../actions/usersListActions";
import axios from "axios";
import swal from "sweetalert";
import {
  GET_USER,
  API_TIMEOUT,
  GET_BANTUAN_TAHUN_KATEGORI,
  GET_BANTUAN_TAHUN_AMBIL,
  GET_BANTUAN,
  DELETE_BANTUAN,
  GET_BANTUAN_TAHUN,
} from "../../../utils";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  DropdownToggle,
  Col,
  Row,
  Badge,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

import Widget from "../../Widget/Widget";

import s from "../Users.module.scss";
import { useDispatch } from "react-redux";
import { numberWithCommas } from "../../../utils/func";
import { logApp } from "../../../utils/func";

const { SearchBar } = Search;

const PenerimaListTable = (props) => {
  const { rows, dispatch, loading, idToDelete, modalOpen } = props;
  const [tableWidth, setTableWidth] = useState(window.innerWidth);
  const [rowsTable, setRowsTable] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [rowsKu, setRowsKu] = useState([]);
  const [idHapus, setIdHapus] = useState();
  const [simpleDropdownValue, setSimpleDropdownValue] = useState("");
  const [simpleDropdownValue2, setSimpleDropdownValue2] = useState("2023");
  const [bantuan, setBantuan] = useState([]);
  const [currentBantuan, setCurrentBantuan] = useState("");
  const [tahun, setTahun] = useState("2023");
  const [jumlahPenerimaBantuan, setJumlahPenerimaBantuan] = useState(0);

  const [modalDelete, setModalDelete] = useState(false);
  const listTahun = [
    { tahun: 2018 },
    { tahun: 2019 },
    { tahun: 2020 },
    { tahun: 2021 },
    { tahun: 2022 },
    { tahun: 2023 },
    { tahun: 2024 },
    { tahun: 2025 },
  ];

  const dispatchKu = useDispatch();

  const changeSelectDropdownSimple = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue(e.currentTarget.textContent);
    const curAnak = bantuan.filter((item) => {
      return item.bantuan == select;
    });
    setCurrentBantuan(curAnak[0].bantuan);
  };
  const changeSelectDropdownSimple2 = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue2(e.currentTarget.textContent);
    const curAnak = listTahun.filter((item) => {
      return item.tahun == select;
    });
    ambilBantuan(curAnak[0].tahun);
    setTahun(curAnak[0].tahun);
  };

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      setCurrentUser(currentUser);
      logApp(currentUser.uid, "Daftar Penerima Bantuan");
    }
  }, []);

  useEffect(() => {
    if (currentUser && tahun) {
      ambilBantuan(tahun);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentBantuan && tahun) {
      ambilDataBantuanKategori(currentBantuan, tahun);
    }
  }, [currentBantuan, tahun]);

  const ambilBantuan = (tahun) => {
    axios({
      method: "post",
      url: GET_BANTUAN_TAHUN,
      timeout: API_TIMEOUT,
      data: {
        tahun: tahun,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;
          let dataFilter = [];
          if (data.length > 0) {
            if (currentUser.peran == "admin") {
              dataFilter = data.filter((item) => {
                return item.dinas == currentUser.dinas;
              });
            } else {
              dataFilter = data;
            }
            if (dataFilter.length > 0) {
              setSimpleDropdownValue(dataFilter[0].bantuan);
              setCurrentBantuan(dataFilter[0].bantuan);
              setBantuan(dataFilter);
            } else {
              setSimpleDropdownValue("");
              setCurrentBantuan("");
              setBantuan(dataFilter);
            }
          } else {
            setSimpleDropdownValue("");
            setCurrentBantuan("");
            setBantuan(dataFilter);
          }
          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  const ambilDataBantuanKategori = (bantuan, tahun) => {
    if (!bantuan || !tahun) {
      swal("Info", "Jenis bantuan dan tahun pelaksanaan perlu dipilih", "info");
      return;
    }

    axios({
      method: "post",
      url: GET_BANTUAN_TAHUN_KATEGORI,
      timeout: API_TIMEOUT,
      data: {
        bantuan: bantuan,
        tahun: tahun,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;
          setJumlahPenerimaBantuan(data.length);
          data.map((item, index) => {
            data[index].nilaiUang = numberWithCommas(item.nilaiUang);
            data[index].umur = hitungUmur(item.dob);
          });

          setRowsKu(data);

          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  const hitungUmur = (dob) => {
    const startDate = new Date(dob);
    const today = new Date();

    const yearDiff = today.getFullYear() - startDate.getFullYear();
    const monthDiff = today.getMonth() - startDate.getMonth();
    const totalMonthDiff = Math.ceil((yearDiff * 12 + monthDiff) / 12) + " thn";
    return totalMonthDiff;
  };

  const handleDelete = () => {
    axios({
      method: "delete",
      url: DELETE_BANTUAN + idHapus,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          swal("Sukses", "Data Penerima berhasil dihapus", "success");
          const idToDelete = idHapus;
          const filteredArray = rowsKu.filter((obj) => obj.id !== idToDelete);
          setRowsKu(filteredArray);
          setModalDelete(false);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };
  const openModal = (cell) => {
    const id = cell;

    setIdHapus(id);
    setModalDelete(true);
  };
  const closeModal = () => {
    setModalDelete(false);
  };
  const actionFormatter = (cell) => {
    return (
      <div className={`d-flex justify-content-between`}>
        <Button
          className={s.controlBtn}
          color="info"
          size="xs"
          onClick={() => props.dispatch(push(`/admin/penerima/${cell}`))}
        >
          View
        </Button>
        <Button
          className={`${s.controlBtn} mx-2`}
          color="success"
          size="xs"
          onClick={() => props.dispatch(push(`/admin/penerima/${cell}/edit`))}
        >
          Edit
        </Button>
        <Button
          className={s.controlBtn}
          color="danger"
          size="xs"
          onClick={() => {
            openModal(cell);
          }}
        >
          Delete
        </Button>
      </div>
    );
  };

  const updateWindowDimensions = () => {
    setTableWidth(window.innerWidth);
  };

  useEffect(() => {
    dispatch(actions.doFetch({}));
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  const columns = [
    {
      dataField: "nama",
      sort: true,
      text: "Nama",
    },
    {
      dataField: "kelompok",
      sort: true,
      text: "Kelompok",
    },
    {
      dataField: "bantuan",
      sort: true,
      text: "Bantuan",
    },
    {
      dataField: "nilaiUang",
      sort: true,
      text: "Nilai Uang",
    },
    {
      dataField: "desa",
      sort: true,
      text: "Desa",
    },

    {
      dataField: "id",
      formatter: actionFormatter,
      text: "Actions",
    },
  ];

  return (
    <div>
      <Widget className="widget-p-md">
        <Row>
          <Col
            className="pr-grid-col"
            xs={2}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <code> Jumlah Penerima Bantuan </code>{" "}
            <Badge style={{ marginLeft: 5 }} color="danger">
              {jumlahPenerimaBantuan}
            </Badge>
          </Col>
          <Col className="pr-grid-col" xs={2}>
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="primary">
                {simpleDropdownValue2}
              </DropdownToggle>

              <DropdownMenu>
                {listTahun &&
                  listTahun.map((item) => (
                    <DropdownItem onClick={changeSelectDropdownSimple2}>
                      {item.tahun}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </Col>

          <Col className="pr-grid-col" xs={5}>
            <UncontrolledButtonDropdown style={{ width: "100%" }}>
              <DropdownToggle
                caret
                color="primary"
                style={{
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {simpleDropdownValue}
              </DropdownToggle>

              <DropdownMenu>
                {bantuan &&
                  bantuan.map((item) => (
                    <DropdownItem onClick={changeSelectDropdownSimple}>
                      {item.bantuan}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </Col>
          <Col className="pr-grid-col" xs={3}>
            <Button
              color="success"
              className="btn"
              onClick={() => dispatchKu(push(`/admin/penerima/new`))}
            >
              Tambah Penerima
            </Button>
          </Col>
        </Row>
        <ToolkitProvider columns={columns} data={rowsKu} keyField="id" search>
          {(props) => (
            <React.Fragment>
              <BootstrapTable
                bordered={false}
                classes={`table-striped table-hover mt-4 ${
                  tableWidth < 1150 ? "table-responsive" : ""
                }`}
                pagination={paginationFactory()}
                {...props.baseProps}
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
      </Widget>
      <Modal size="sm" isOpen={modalDelete} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>Konfirmasi hapus</ModalHeader>
        <ModalBody>
          apakah anda yakin akan menghapus data penerima bantuan ini?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => handleDelete()}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    loading: store.users.list.loading,
    rows: store.users.list.rows,
    modalOpen: store.users.list.modalOpen,
    idToDelete: store.users.list.idToDelete,
  };
}

export default connect(mapStateToProps)(PenerimaListTable);
