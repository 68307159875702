import React, { useState } from "react";
import swal from "sweetalert";
import CustomPopover from "../../CustomPopover/CustomPopover";

import Widget from "../../Widget/Widget";
import DatePicker from "react-datepicker";
import { toSmallCaps } from "../../../utils/func";
import {
  Col,
  Row,
  Button,
  ButtonGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Form,
  FormGroup,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Badge,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
  Spinner,
} from "reactstrap";
import {
  POST_USER,
  API_TIMEOUT,
  GET_USER,
  GET_BANTUAN_TAHUN_AMBIL,
  CARI_DATA_MISKIN_LIKE,
  GET_BANTUAN,
  DATA_BANTUAN_API,
  GET_BANTUAN_TAHUN,
  HISTORI_BANTUAN_API,
  AKUMULASI_BANTUAN_TAHUN,
  VALIDASI_BANTUAN,
  VALIDASI_HISTORI,
  CARI_DATA_MISKIN_LIKE_DTKS,
  CARI_NIK_DTKS,
  CARI_NIK_DUKCAPIL,
  VALIDASI_DIFABEL,
} from "../../../utils";
import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router";
import s from "./Elements.module.scss";

import { useDispatch } from "react-redux";
import { GET_DESA, GET_KECAMATAN } from "../../../utils";
import { push } from "connected-react-router";
import { padEnd, set, update } from "lodash";
import { numberWithCommas } from "../../../utils/func";
import Success from "../../../assets/success.png";
import Stop from "../../../assets/stop.png";
import Locked from "../../../assets/locked.png";
import UnLocked from "../../../assets/unlocked.png";
import Difabel from "../../../assets/difable.png";

import { logApp } from "../../../utils/func";

const PenerimaForm = () => {
  const [startDate, setStartDate] = useState("");

  const [nama, setNama] = useState("");
  const [nik, setNik] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [catatan, setCatatan] = useState("");
  const [foto, setFoto] = useState("");
  const [noKK, setNoKK] = useState("");
  const [bantuan, setBantuan] = useState("");
  const [nilaiUang, setNilaiUang] = useState("");
  const [tahun, setTahun] = useState("2023");
  const [status, setStatus] = useState("");

  const [urlFile1, setUrlFile1] = useState();
  const [fotoAnak, setFotoAnak] = useState([]);
  const [rubahGambar, setRubahGambar] = useState("false");
  const [kkDifable, setKKDifable] = useState({ status: false, data: "" });

  const [idKeluarga, setIdKeluarga] = useState("");
  const [idIndividu, setIdIndividu] = useState("");

  const [listSelected, setListSelected] = useState([]);
  const [simpleDropdownValue2, setSimpleDropdownValue2] = useState("2023");
  const [preSelectedItem, setPreSelectedItem] = useState("");
  const [sinkronItem, setSinkronItem] = useState();
  const [modalDifabel, setModalDifabel] = useState(false);
  const [modalKunci, setModalKunci] = useState(false);

  const { id } = useParams();
  const { idView } = useParams();
  const dispatch = useDispatch();

  const [kecamatan, setKecamatan] = useState("");
  const [desa, setDesa] = useState("");

  const [kecamatan2, setKecamatan2] = useState("");
  const [desa2, setDesa2] = useState("");

  const [listDesa, setListDesa] = useState([]);
  const [listDesaPilihan, setListDesaPilihan] = useState([]);
  const [listDesaPilihan2, setListDesaPilihan2] = useState([]);
  const [listKecamatan, setListKecamatan] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [infoSelected, setInfoSelected] = useState();
  const [idSmartUser, setIdSmartUser] = useState("");

  const [simpleDropdownValue, setSimpleDropdownValue] = useState("");
  const [bantuanKu, setBantuanKu] = useState([]);
  const [currentBantuan, setCurrentBantuan] = useState("");
  const [listHistoriBantuan, setListHistoriBantuan] = useState([]);
  const [modaHistori, setModalHistori] = useState(false);
  const [modaValidasi, setModalValidasi] = useState(false);
  const [modaValidasiBantuan, setModalValidasiBantuan] = useState(false);
  const [kelompok, setKelompok] = useState("");
  const [pesanValidasi, setPesanValidasi] = useState("");
  const [hasilValidasi, setHasilValidasi] = useState(false);

  const [validasiAkumulasiBantuan, setValidasiAkumulasiBantuan] = useState();
  const [validasiTotalPenerima, setValidasiTotalPenerima] = useState();

  const [validasiHistoriPenerima, setValidasiHistoriPenerima] = useState();

  const [checkBantuanTahunSama, setCheckBantuanTahunSama] = useState(false);

  const [nikValidasi, setNikValidasi] = useState("");

  const [isCheckedNama, setIsCheckedNama] = useState(true);
  const [isCheckedNik, setIsCheckedNik] = useState(false);

  const [isCheckedDTKS, setIsCheckedDTKS] = useState(true);
  const [isCheckedDukcapil, setIsCheckedDukcapil] = useState(false);

  const [isCheckedSinkron, setIsCheckedSinkron] = useState(false);

  const [nikError, setNikError] = useState("");
  const [modalNik, setModalNik] = useState(false);

  const [currentUser, setCurrentUser] = useState();

  const [cari, setCari] = useState(false);
  const [validasiButton, setValidasiButton] = useState(false);

  const [kunciAturan, setKunciAturan] = useState(true);
  const [limaPuluhME, setLimaPuluhME] = useState(false);
  const [catatanBuruk, setCatatanBuruk] = useState(false);
  const [limaPuluhJuta, setLimaPuluhJuta] = useState(false);
  const [quota, setQuota] = useState(false);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    // Untuk "Ya" di-check, set "Tidak" menjadi false
    if (name === "nama" && checked) {
      setIsCheckedNama(true);
      setIsCheckedNik(false);
    }
    // Untuk "Tidak" di-check, set "Ya" menjadi false
    else if (name === "nik" && checked) {
      setIsCheckedNama(false);
      setIsCheckedNik(true);
    }
    // Jika checkbox di-uncheck, set keduanya menjadi false
    else {
      setIsCheckedNama(false);
      setIsCheckedNik(false);
    }
  };

  const handleCheckboxChange3 = (event) => {
    const { name, checked } = event.target;

    // Untuk "Ya" di-check, set "Tidak" menjadi false
    if (name === "sinkron" && checked) {
      setIsCheckedSinkron(true);
    } else {
      setIsCheckedSinkron(false);
    }
  };

  const setTanggalLahirDtks = (dob) => {
    const fix = dob.split(" ");
    return fix[0];
  };

  const setTanggalLahirDukcapil = (dob) => {
    const bulan = {
      JAN: 1,
      FEB: 2,
      MAR: 3,
      APR: 4,
      MAY: 5,
      JUN: 6,
      JUL: 7,
      AUG: 8,
      SEP: 9,
      OCT: 10,
      NOV: 11,
      DEC: 12,
    };
    const fix = dob.split("-");
    const bul = bulan[fix[1]];
    const bul2 = bul < 10 ? "0" + bul.toString() : bul;
    const tahun = parseInt(fix[2]);
    let thn = "";
    if (tahun < 30) {
      thn = "20" + fix[2];
    } else {
      thn = "19" + fix[2];
    }
    return fix[0] + "/" + bul2 + "/" + thn;
  };

  const handleCheckboxChange2 = (event) => {
    const { name, checked } = event.target;

    // Untuk "Ya" di-check, set "Tidak" menjadi false
    if (name === "dtks" && checked) {
      setIsCheckedDTKS(true);
      setIsCheckedDukcapil(false);
    }
    // Untuk "Tidak" di-check, set "Ya" menjadi false
    else if (name === "dukcapil" && checked) {
      setIsCheckedDTKS(false);
      setIsCheckedDukcapil(true);
    }
    // Jika checkbox di-uncheck, set keduanya menjadi false
    else {
      setIsCheckedDTKS(false);
      setIsCheckedDukcapil(false);
    }
  };

  const containerStyle = {
    width: "100%",
    height: "300px", // Atur tinggi sesuai kebutuhan Anda
    overflow: "auto",
    /* Gaya tampilan scroll */
    scrollbarWidth: "thin",
    scrollbarColor: "#888888 #f0f0f0",
  };

  const closeModal = () => {
    setModalHistori(false);
  };
  const closeModal2 = () => {
    setModalValidasi(false);
    setKKDifable({ status: false, data: "" });
  };
  const closeModal3 = () => {
    setCatatanBuruk(false);
    setQuota(false);
    setLimaPuluhJuta(false);
    setLimaPuluhME(false);
    setModalValidasiBantuan(false);
  };

  useEffect(() => {
    getListDesa();
    getListKecamatan();

    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      setIdSmartUser(currentUser.uid);
      setCurrentUser(currentUser);
      logApp(currentUser.uid, "Form Penerima Bantuan");
    }
  }, []);

  useEffect(() => {
    if (!id && !idView) {
      if (currentUser) {
        ambilBantuan(tahun);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (!id && !idView) {
      if (currentUser) {
        ambilBantuan(tahun);
      }
    }
  }, [tahun]);

  const ambilBantuan = (tahun) => {
    axios({
      method: "post",
      url: GET_BANTUAN_TAHUN,
      timeout: API_TIMEOUT,
      data: {
        tahun: tahun,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          let dataFilter = [];
          if (data.length > 0) {
            if (id || idView) {
            } else {
              if (currentUser.peran == "admin") {
                dataFilter = data.filter((item) => {
                  return item.dinas == currentUser.dinas;
                });
              } else {
                dataFilter = data;
              }

              if (dataFilter.length > 0) {
                setBantuan(dataFilter[0].bantuan);
                setSimpleDropdownValue(dataFilter[0].bantuan);
              } else {
                setBantuan("");
                setSimpleDropdownValue("");
              }
            }
            console.log("data filter  ", dataFilter);
            setCurrentBantuan(dataFilter[0]);
            setBantuanKu(dataFilter);

            //setFirstTable(response.data.data);
          } else {
            setSimpleDropdownValue("");
            setBantuan("");
            setBantuanKu(data);
          }
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  const openModalHistori = (data) => {
    ambilHistori(data);
    setModalHistori(true);
  };

  function leftJoin(array1, array2, joinKey) {
    const xx = array1.map((item1) => {
      const matchedItems = array2.filter(
        (item2) => item2[joinKey] === item1[joinKey]
      );
      return { ...item1, matchedItems };
    });

    return xx;
  }

  const validasiDifable = (noKK) => {
    axios({
      method: "get",
      url: VALIDASI_DIFABEL + noKK,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const dataKu = response.data.data;

          if (dataKu.length > 0) {
            setKKDifable({ status: true, data: dataKu[0] });
          } else {
            setKKDifable({ status: false, data: "" });
          }
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  const validasiPenerima = (data) => {
    setNikValidasi(data.nik);
    if (!isCheckedDTKS) {
      //lakukan pencarian kk difable
      validasiDifable(data.noKK);
      axios({
        method: "get",
        url: CARI_NIK_DTKS + data.nik,
        timeout: API_TIMEOUT,
      })
        .then((response) => {
          if (response.status !== 200) {
            // ERROR
            return "error";
          } else {
            //BERHASIL

            const dataKu = response.data.data;

            if (dataKu.length > 0) {
              dataKu[0].dob = setTanggalLahirDtks(dataKu[0].tanggalLahir);

              setSinkronItem(dataKu[0]);
            }

            setModalValidasi(true);
          }
        })
        .catch((error) => {
          // ERROR
          alert(error);
        });
    } else if (!isCheckedDukcapil) {
      axios({
        method: "get",
        url: CARI_NIK_DUKCAPIL + data.nik,
        timeout: API_TIMEOUT,
      })
        .then((response) => {
          if (response.status !== 200) {
            // ERROR
            return "error";
          } else {
            //BERHASIL

            const dataKu = response.data.data;

            if (dataKu.length > 0) {
              dataKu[0].dob = setTanggalLahirDukcapil(dataKu[0].tanggalLahir);
              setSinkronItem(dataKu[0]);
              validasiDifable(dataKu[0].noKK);
            }

            setModalValidasi(true);
          }
        })
        .catch((error) => {
          // ERROR
          alert(error);
        });
    }
  };

  const validasiPenerima2 = () => {
    axios({
      method: "post",
      url: AKUMULASI_BANTUAN_TAHUN,
      timeout: API_TIMEOUT,
      data: {
        nik: nik,
        tahun: tahun,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const dataKu = response.data.data;

          if (dataKu.length > 0) {
            const total = dataKu[0].total_bantuan + parseInt(nilaiUang);

            if (total >= 50000000) {
              setLimaPuluhJuta(false);
              setValidasiAkumulasiBantuan({
                status: false,
                total: total,
              });
            } else {
              setLimaPuluhJuta(true);
              setValidasiAkumulasiBantuan({
                status: true,
                total: total,
              });
            }
          } else {
            setLimaPuluhJuta(true);
            setValidasiAkumulasiBantuan({
              status: false,
              total: 0,
            });
          }
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  const ambilHistori = (data) => {
    axios({
      method: "post",
      url: HISTORI_BANTUAN_API,
      timeout: API_TIMEOUT,
      data: data,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const tahun = [
            {
              id: 1,
              tahun: "2018",
            },
            {
              id: 2,
              tahun: "2019",
            },
            {
              id: 3,
              tahun: "2020",
            },
            {
              id: 4,
              tahun: "2021",
            },
            {
              id: 5,
              tahun: "2022",
            },
            {
              id: 6,
              tahun: "2023",
            },
            {
              id: 7,
              tahun: "2024",
            },
            {
              id: 8,
              tahun: "2025",
            },
          ];

          const data = response.data.data;

          const mix = leftJoin(tahun, data, "tahun");
          mix.map((item) => {
            if (item.matchedItems.length > 0) {
              item.matchedItems.map((item2) => {
                if (item2.status == "Hitam") {
                  setCatatanBuruk(true);
                }
              });
            }
          });

          setListHistoriBantuan(mix);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  useEffect(() => {
    if (id) {
      cariData(id);
    }
    if (idView) {
      cariData(idView);
    }
  }, [id, idView]);

  const cariItems = (item, kecamatan, desa) => {
    setCari(true);
    if (item == "") {
      swal("Info", "nama/nik yang dicari tidak boleh kosong", "info");
      setCari(false);
      return;
    } else if (desa == "" && kecamatan2 && isCheckedDukcapil) {
      setListSelected([]);
      setCari(false);
      return;
    }

    let kolomCari = "";
    if (isCheckedNama) {
      kolomCari = "nama";
    } else {
      kolomCari = "nik";
    }

    if (isCheckedDTKS) {
      const dataKu = {
        item: item,
        desa: desa,
        kecamatan: kecamatan,
        kolomcari: kolomCari,
      };
      axios({
        method: "post",
        url: CARI_DATA_MISKIN_LIKE,
        timeout: API_TIMEOUT,
        data: dataKu,
      })
        .then((response) => {
          if (response.status !== 200) {
            // ERROR
            return "error";
          } else {
            //BERHASIL

            const result = response.data.data;

            setListSelected(result);
            setCari(false);
          }
        })
        .catch((error) => {
          // ERROR
          setCari(false);
          alert(error);
        });
    } else if (isCheckedDukcapil) {
      const dataKu = {
        item: item,
        desa: desa,
        kecamatan: kecamatan,
        kolomcari: kolomCari,
      };
      axios({
        method: "post",
        url: CARI_DATA_MISKIN_LIKE_DTKS,
        timeout: API_TIMEOUT,
        data: dataKu,
      })
        .then((response) => {
          if (response.status !== 200) {
            // ERROR
            return "error";
          } else {
            //BERHASIL

            const result = response.data.data;

            setListSelected(result);
            setCari(false);
          }
        })
        .catch((error) => {
          // ERROR
          setCari(false);
          alert(error);
        });
    }
  };

  const ubahKecamatan = (kecamatan) => {
    var listDesaPilih = listDesa.filter((item) => {
      return item.kecamatan == kecamatan;
    });

    var listDesaPilihFilter = listDesaPilih.map((item, index) => {
      return { id: index, label: item.desa, value: item.desa };
    });
    setListDesaPilihan(listDesaPilihFilter);
    setDesa("");
    setKecamatan(kecamatan);
  };

  const ubahKecamatan3 = (kecamatan) => {
    var listDesaPilih = listDesa.filter((item) => {
      return item.kecamatan == kecamatan;
    });

    var listDesaPilihFilter = listDesaPilih.map((item, index) => {
      return { id: index, label: item.desa, value: item.desa };
    });
    setListDesaPilihan2(listDesaPilihFilter);
    setDesa2("");
    setKecamatan2(kecamatan);
  };

  useEffect(() => {
    if (kecamatan && desa) {
      var listDesaPilih = listDesa.filter((item) => {
        return item.kecamatan == kecamatan;
      });

      var listDesaPilihFilter = listDesaPilih.map((item, index) => {
        return { id: index, label: item.desa, value: item.desa };
      });
      setListDesaPilihan(listDesaPilihFilter);
    }
  }, [desa]);

  const ubahKecamatan2 = (kecamatan, desa) => {
    if (kecamatan && desa) {
      var listDesaPilih = listDesa.filter((item) => {
        return item.kecamatan == kecamatan;
      });

      var listDesaPilihFilter = listDesaPilih.map((item, index) => {
        return { id: index, label: item.desa, value: item.desa };
      });
      setKecamatan(kecamatan);
      setListDesaPilihan(listDesaPilihFilter);
      setDesa(desa);
    }
  };

  //untuk data dukcapil yang tidak ada data kecamatan

  const ubahKecamatan4 = (desa) => {
    if (desa) {
      var desaKu = listDesa.filter((item) => {
        return item.desa == desa;
      });

      let kecamatan = "";

      if (desaKu.length > 0) {
        kecamatan = desaKu[0].kecamatan;
      } else {
        setKecamatan("");
        setDesa("");
        return;
      }
      var listDesaPilih = listDesa.filter((item) => {
        return item.kecamatan == kecamatan;
      });

      var listDesaPilihFilter = listDesaPilih.map((item, index) => {
        return { id: index, label: item.desa, value: item.desa };
      });
      setKecamatan(kecamatan);
      setListDesaPilihan(listDesaPilihFilter);
      setDesa(desa);
    } else {
      setKecamatan("");
      setDesa("");
    }
  };

  const getListDesa = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_DESA);
      data1 = response.data.data;

      if (data1.length > 0) {
        setListDesa(data1);
      } else {
        setListDesa([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  const getListKecamatan = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_KECAMATAN);
      data1 = response.data.data;

      if (data1.length > 0) {
        var dataku = data1.map((item, index) => {
          return { id: index, label: item.kecamatan, value: item.kecamatan };
        });
        setListKecamatan(dataku);
      } else {
        setListKecamatan([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  function toProperCase(str) {
    return str.toLowerCase().replace(/^(.)|\s(.)/g, function ($1) {
      return $1.toUpperCase();
    });
  }

  const cariData = (id) => {
    const dataKu = { id: id };

    axios({
      method: "get",
      url: DATA_BANTUAN_API + id,
      timeout: API_TIMEOUT,

      data: dataKu,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const result = response.data.data[0];

          if (response.data.data.length == 0) {
            return;
          }

          const tgl = new Date(result.dob);
          const year = tgl.getFullYear();
          const month = String(tgl.getMonth() + 1).padStart(2, "0");
          const day = String(tgl.getDate()).padStart(2, "0");
          const date_string = `${year}-${month}-${day}`;

          setNama(result.nama);
          setNik(result.nik);

          setDob(date_string);
          setGender(result.gender);
          setCatatan(result.catatan);
          setFoto(result.foto);
          setStatus(result.status);
          setTahun(result.tahun);
          setNoKK(result.noKK);
          setIdIndividu(result.idIndividu);
          setIdKeluarga(result.idKeluarga);
          setNilaiUang(result.nilaiUang);
          setNoKK(result.noKK);
          setKelompok(result.kelompok);
          setBantuan(result.bantuan);

          setUrlFile1(result.profile);
          ubahKecamatan2(result.kecamatan, result.desa);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const changeSelectDropdownSimple2 = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue2(e.currentTarget.textContent);
    const curAnak = listTahun.filter((item) => {
      return item.tahun == select;
    });
    setTahun(curAnak[0].tahun);

    // ambilDataBantuanKategori(currentBantuan, curAnak[0].tahun);
  };

  const setDataSelected = (result) => {
    let tgl = result.dob;

    const tanggal = tgl.split("/");
    const date_string = `${tanggal[2]}-${tanggal[1]}-${tanggal[0]}`;

    setNama(result.nama);
    setNik(result.NIK);

    setGender(result.jenisKelamin);
    setCatatan("");

    if (isCheckedDukcapil && isCheckedSinkron) {
      setIdIndividu(sinkronItem.idIndividu);
      setIdKeluarga(sinkronItem.idKeluarga);
    } else if (isCheckedDukcapil && !isCheckedSinkron) {
      setIdIndividu("");
      setIdKeluarga("");
    } else if (!isCheckedDukcapil && !isCheckedSinkron) {
      setIdIndividu(result.idIndividu);
      setIdKeluarga(result.idKeluarga);
    } else if (!isCheckedDukcapil && isCheckedSinkron) {
      setIdIndividu(result.idIndividu);
      setIdKeluarga(result.idKeluarga);
    }
    setKelompok("");

    if (isCheckedDTKS && isCheckedSinkron) {
      setNoKK(sinkronItem.noKK);
    } else if (isCheckedDTKS && !isCheckedSinkron) {
      setNoKK("");
    } else if (!isCheckedDTKS && !isCheckedSinkron) {
      setNoKK(result.noKK);
    } else if (!isCheckedDTKS && isCheckedSinkron) {
      setNoKK(result.noKK);
    }
    setUrlFile1(result.profile);

    if (isCheckedDTKS) {
    }

    if (isCheckedDukcapil && isCheckedSinkron) {
      ubahKecamatan4(toProperCase(sinkronItem.desa));
    } else if (isCheckedDukcapil && !isCheckedSinkron) {
      ubahKecamatan4(toProperCase(result.alamat ? result.alamat : ""));
    }

    isCheckedDTKS && ubahKecamatan4(toProperCase(result.desa));
    setDob(date_string);
  };

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFile1(URL.createObjectURL(uploaded));
    setFotoAnak(e.target.files[0]);
    setRubahGambar("true");
  }

  const editData = async () => {
    const tgl = new Date(dob);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, "0");
    const day = String(tgl.getDate()).padStart(2, "0");
    const date_string = `${year}-${month}-${day}`;

    const data = {
      nama: nama,
      nik: nik,
      dob: date_string,
      gender: gender,

      bantuan: bantuan,
      nilaiUang: nilaiUang,
      desa: desa,
      kecamatan: kecamatan,
      noKK: noKK,
      tahun: tahun,
      catatan: catatan,
      status: status,
      idKeluarga: idKeluarga,
      idIndividu: idIndividu,
      kelompok: kelompok,
    };

    const response = await axios.patch(DATA_BANTUAN_API + id, data);

    if (response.data.success === 1) {
      swal("Sukses", "Data Penerima berhasil diedit", "success");
      dispatch(push("/admin/penerima"));
    } else {
      swal("Gagal", "Data Penerima gagal diinput", "failed");
    }
  };

  const tambahData = async () => {
    const tgl = new Date(dob);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, "0");
    const day = String(tgl.getDate()).padStart(2, "0");
    const date_string = `${year}-${month}-${day}`;

    const data = {
      nama: nama,
      nik: nik,
      dob: date_string,
      gender: gender,

      bantuan: bantuan,
      nilaiUang: nilaiUang,
      desa: desa,
      kecamatan: kecamatan,
      noKK: noKK,
      tahun: tahun,
      catatan: catatan,
      status: status,
      idKeluarga: idKeluarga,
      idIndividu: idIndividu,
      kelompok: kelompok,
    };

    axios({
      method: "post",
      url: DATA_BANTUAN_API,
      timeout: API_TIMEOUT,

      data: data,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR

          swal("Gagal", " Data Penerima Bantuan gagal diinput", "failed");
          return "error";
        } else {
          //BERHASIL

          swal("Sukses", "Data Penerima Bantuan berhasil diinput", "success");
          setNama("");
          setNik("");
          setDob("");
          setGender("");
          setCatatan("");
          setFotoAnak([]);

          setStatus("");
          setKelompok("");

          setDesa("");
          setKecamatan("");
          setNoKK("");
          setIdIndividu("");
          setIdKeluarga("");

          setNilaiUang("");
          setUrlFile1("");
        }
      })
      .catch((error) => {
        // ERROR

        swal("Gagal", " Data Penerima Bantuan gagal diinput", "failed");
      });
  };

  const listTahun = [
    { tahun: 2018 },
    { tahun: 2019 },
    { tahun: 2020 },
    { tahun: 2021 },
    { tahun: 2022 },
    { tahun: 2023 },
    { tahun: 2024 },
    { tahun: 2025 },
    { tahun: 2026 },
  ];

  const validasiHistori = () => {
    axios({
      method: "post",
      url: VALIDASI_HISTORI,
      timeout: API_TIMEOUT,
      data: {
        bantuan: bantuan,
        nik: nik,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const dataKu = response.data.data;

          if (dataKu.length > 0) {
            setValidasiHistoriPenerima(dataKu);
            const check = dataKu.filter((item) => {
              return item.tahun == tahun && item.bantuan == bantuan;
            });

            if (check.length > 0) {
              setCheckBantuanTahunSama(true);
            } else {
              setCheckBantuanTahunSama(false);
            }
          } else {
            setValidasiHistoriPenerima();
          }
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  const validasiBantuan = () => {
    axios({
      method: "post",
      url: VALIDASI_BANTUAN,
      timeout: API_TIMEOUT,
      data: {
        bantuan: bantuan,
        tahun: tahun,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const dataKu = response.data.data;

          if (dataKu.length > 0) {
            const data1 = dataKu[0];
            const totalPenerima = parseInt(data1.jmlPenerima);
            const penerimaMiskinEkstrim = data1.jumlahmiskinekstrim;
            const penerima = data1.jumlahpenerima;
            const limit = Math.floor(totalPenerima / 2);
            const penerimaNon = penerima - penerimaMiskinEkstrim;

            penerimaNon < limit
              ? setLimaPuluhME(true)
              : idKeluarga
              ? setLimaPuluhME(true)
              : setLimaPuluhME(false);
            totalPenerima > penerima ? setQuota(true) : setQuota(false);
            setValidasiTotalPenerima({
              totalPenerima: totalPenerima,
              penerima: penerima,
              penerimaMiskinEkstrim: penerimaMiskinEkstrim,
            });
          } else {
            setValidasiTotalPenerima({
              totalPenerima: 0,
              penerima: 0,
              penerimaMiskinEkstrim: 0,
            });
          }
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  const simpanData = (e) => {
    setValidasiButton(true);
    e.preventDefault();

    if (nikError || nik.length != 16) {
      setModalNik(true);
      setValidasiButton(false);
    } else {
      if (id) {
        editData();
      } else {
        const data = {};
        data.kolom = "nik";
        data.data = nik;
        ambilHistori(data);
        validasiBantuan();
        validasiPenerima2();
        validasiHistori();
        setModalValidasiBantuan(true);
        setValidasiButton(false);
      }
    }
  };

  function validateNik(value) {
    setNik(value);
    if (value.length != 16) {
      setNikError("harus 16 characters");
    } else {
      setNikError("");
    }
  }

  const lanjutkan = () => {
    tambahData();
  };

  const changeSelectDropdownSimple = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue(e.currentTarget.textContent);
    const curAnak = bantuanKu.filter((item) => {
      return item.bantuan == select;
    });
    setCurrentBantuan(curAnak[0]);
    setBantuan(curAnak[0].bantuan);
  };

  const renderForm = () => (
    <div>
      <Widget className="widget-p-md">
        {id || idView ? (
          <div />
        ) : (
          <Row>
            <Col className="pr-grid-col" xs={4}>
              <UncontrolledButtonDropdown>
                <DropdownToggle caret color="primary">
                  {simpleDropdownValue2}
                </DropdownToggle>

                <DropdownMenu>
                  {listTahun &&
                    listTahun.map((item) => (
                      <DropdownItem onClick={changeSelectDropdownSimple2}>
                        {item.tahun}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </Col>
            <Col>
              <UncontrolledButtonDropdown style={{ width: 300 }}>
                <DropdownToggle
                  caret
                  color="primary"
                  style={{
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {simpleDropdownValue}
                </DropdownToggle>

                <DropdownMenu>
                  {bantuanKu &&
                    bantuanKu.map((item) => (
                      <DropdownItem onClick={changeSelectDropdownSimple}>
                        {item.bantuan}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="danger"
                className="btn"
                onClick={() => setModalOpen(true)}
              >
                <span> Cari</span>
              </Button>
            </Col>
          </Row>
        )}
        <FormGroup>
          <Form onSubmit={(e) => simpanData(e)}>
            <legend className="headline-2">
              {id ? "Edit" : idView ? "Lihat" : "Input"}
            </legend>
            <FormGroup row>
              <Label md={3} for="normal-field" className="text-md-right">
                Nama
              </Label>
              <Col md={9}>
                <Input
                  type="text"
                  id="normal-field"
                  placeholder=""
                  value={nama}
                  onChange={(e) => setNama(e.target.value)}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label
                md={3}
                for="hint-field"
                className="d-flex flex-column text-md-right"
              >
                NIK
                {nikError ? (
                  <span className="label muted" style={{ color: "red" }}>
                    {nikError}
                  </span>
                ) : (
                  <span className="label muted">Nomor Induk Kependudukan</span>
                )}
              </Label>
              <Col md={9}>
                <Input
                  type="number"
                  name="password"
                  id="hint-field"
                  value={nik}
                  onChange={(e) => validateNik(e.target.value)}
                  style={{ borderColor: nikError ? "red" : "initial" }}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={3} for="disabled-input" className="text-md-right">
                Tanggal Lahir
              </Label>
              <Col md="9">
                <Input
                  type="date"
                  id="normal-field"
                  onChange={(e) => setDob(e.target.value)}
                  dateFormat="dd/mm/yyyy"
                  placeholder=""
                  value={dob}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={3} for="normal-field" className="text-md-right">
                Gender
              </Label>
              <Col md={9}>
                <Input
                  type="select"
                  id="normal-field"
                  placeholder=""
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  required
                >
                  <option value={""}> pilih gender</option>
                  <option value={"Laki-laki"}>Laki-laki</option>
                  <option value={"Perempuan"}>Perempuan</option>
                </Input>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label md={3} for="normal-field" className="text-md-right">
                Nama Bantuan Sosial
              </Label>
              <Col md={9}>
                <Input
                  type="text"
                  id="normal-field"
                  placeholder=""
                  value={bantuan}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={3} for="normal-field" className="text-md-right">
                Nomor KK
              </Label>
              <Col md={9}>
                <Input
                  type="number"
                  id="normal-field"
                  maxLength={10}
                  placeholder=""
                  value={noKK}
                  onChange={(e) => setNoKK(e.target.value)}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label md={3} for="normal-field" className="text-md-right">
                Nilai Uang
              </Label>
              <Col md={9}>
                <Input
                  type="number"
                  id="normal-field"
                  placeholder=""
                  value={nilaiUang}
                  onChange={(e) => setNilaiUang(e.target.value)}
                  required
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label md={3} for="normal-field" className="text-md-right">
                Nama Kelompok
              </Label>
              <Col md={9}>
                <Input
                  type="text"
                  id="normal-field"
                  placeholder=""
                  value={kelompok}
                  onChange={(e) => setKelompok(e.target.value)}
                  required
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label md={3} for="normal-field" className="text-md-right">
                Id Keluarga
              </Label>
              <Col md={9}>
                <Input
                  type="number"
                  id="normal-field"
                  placeholder=""
                  value={idKeluarga}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label md={3} for="normal-field" className="text-md-right">
                Id Individu
              </Label>
              <Col md={9}>
                <Input
                  type="number"
                  id="normal-field"
                  placeholder=""
                  value={idIndividu}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label md={3} for="normal-field" className="text-md-right">
                Kecamatan
              </Label>
              <Col md={9}>
                <Input
                  type="select"
                  id="normal-field"
                  placeholder=""
                  value={kecamatan}
                  onChange={(e) => ubahKecamatan(e.target.value)}
                  required
                >
                  <option value={""}> pilih Kecamatan</option>
                  {listKecamatan &&
                    listKecamatan.map((item, index) => (
                      <option value={item.label} key={index}>
                        {item.label}
                      </option>
                    ))}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={3} for="normal-field" className="text-md-right">
                Desa
              </Label>
              <Col md={9}>
                <Input
                  type="select"
                  id="normal-field"
                  placeholder=""
                  value={desa}
                  onChange={(e) => setDesa(e.target.value)}
                  required
                >
                  <option value={""}> pilih Desa</option>
                  {listDesaPilihan &&
                    listDesaPilihan.map((item, index) => (
                      <option value={item.label} key={index}>
                        {item.label}
                      </option>
                    ))}
                </Input>
              </Col>
            </FormGroup>

            <Widget className="widget-p-md">
              <div className="headline-2 mb-3">Upload foto anak</div>
              <FormGroup row>
                <Label lg="3" className="text-md-right mt-3">
                  File input
                </Label>
                <Col lg="9">
                  <div className="input-group mb-4 px-2 py-2 rounded-pill bg-light-gray">
                    <input
                      id="upload"
                      type="file"
                      onChange={onChange1}
                      className={`form-control border-0 ${s.upload}`}
                    />
                    <label
                      id="upload-label"
                      htmlFor="upload"
                      className={`font-weight-light text-muted ${s.uploadLabel}`}
                    >
                      {fotoAnak ? fotoAnak.name : "Pilih file"}
                    </label>
                    <div className="input-group-append">
                      <label
                        htmlFor="upload"
                        className="btn btn-light m-0 rounded-pill px-4"
                      >
                        <i className="fa fa-cloud-upload mr-2 text-muted"></i>
                      </label>
                    </div>
                  </div>
                  {urlFile1 && (
                    <div>
                      <div className={`${s.container}`}>
                        <img
                          id="imageResult"
                          src={urlFile1}
                          alt=""
                          className={`${s.imageKu}`}
                        />
                      </div>
                    </div>
                  )}
                </Col>
              </FormGroup>
            </Widget>
            {!idView && (
              <FormGroup row>
                <Label md={3} />
                <Col md={9}>
                  {validasiButton ? (
                    <Button color="default" disabled className="mr-3 mt-3">
                      <Spinner size="sm" color="light" /> Loading
                    </Button>
                  ) : (
                    <Button color="primary" type="submit" className="mr-3 mt-3">
                      {id ? "Simpan" : "Validasi"}
                    </Button>
                  )}
                  <Button
                    color="secondary-red"
                    className="mt-3"
                    type="button"
                    onClick={() => dispatch(push(`/admin/penerima`))}
                  >
                    Batal
                  </Button>
                </Col>
              </FormGroup>
            )}
          </Form>
        </FormGroup>
      </Widget>
      <Modal isOpen={modalOpen}>
        <ModalBody>
          <Widget className="widget-p-md">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div className="mt-2 mb-3 body-3 muted">
                Lakukan pencarian orang{" "}
                <Badge
                  color="secondary-red"
                  style={{ cursor: "pointer" }}
                  onClick={() => ""}
                >
                  {listSelected.length}
                </Badge>
              </div>
              <div
                className="mt-2 mb-3 body-3 muted"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: 10,
                  }}
                >
                  <div className="checkbox checkbox-primary">
                    <input
                      className="styled"
                      id="checkbox100"
                      type="checkbox"
                      name="nama"
                      checked={isCheckedNama}
                      onChange={handleCheckboxChange}
                    />
                    <label for="checkbox100" />
                  </div>
                  <div>Nama</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 10,
                  }}
                >
                  <div className="checkbox checkbox-primary">
                    <input
                      className="styled"
                      id="checkbox101"
                      type="checkbox"
                      name="nik"
                      checked={isCheckedNik}
                      onChange={handleCheckboxChange}
                    />
                    <label for="checkbox101" />
                  </div>
                  <div>NIK</div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <div className="mt-2 mb-3 body-3 muted">
                <Badge
                  color="success"
                  style={{ cursor: "pointer" }}
                  onClick={() => ""}
                >
                  Sumber Data
                </Badge>
              </div>
              <div
                className="mt-2 mb-3 body-3 muted"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginLeft: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: 10,
                  }}
                >
                  <div className="checkbox checkbox-primary">
                    <input
                      className="styled"
                      id="checkbox104"
                      type="checkbox"
                      name="dtks"
                      checked={isCheckedDTKS}
                      onChange={handleCheckboxChange2}
                    />
                    <label for="checkbox104" />
                  </div>
                  <div>DTKS</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 10,
                  }}
                >
                  <div className="checkbox checkbox-primary">
                    <input
                      className="styled"
                      id="checkbox105"
                      type="checkbox"
                      name="dukcapil"
                      checked={isCheckedDukcapil}
                      onChange={handleCheckboxChange2}
                    />
                    <label for="checkbox105" />
                  </div>
                  <div>Dukcapil</div>
                </div>
              </div>
            </div>
            <FormGroup>
              <InputGroup>
                <Input
                  type="text"
                  id="search-input1"
                  value={searchItem}
                  onChange={(e) => setSearchItem(e.target.value)}
                />
                <InputGroupAddon addonType="append">
                  {cari ? (
                    <Button color="default" disabled>
                      <Spinner size="sm" color="light" /> Loading
                    </Button>
                  ) : (
                    <Button
                      color="default"
                      onClick={() => cariItems(searchItem, kecamatan2, desa2)}
                    >
                      Search
                    </Button>
                  )}
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col>
                  <Input
                    type="select"
                    id="normal-field"
                    placeholder="gender"
                    value={kecamatan2}
                    onChange={(e) => {
                      ubahKecamatan3(e.target.value);
                      cariItems(searchItem, e.target.value, "");
                    }}
                    required
                  >
                    <option value={""}> pilih Kecamatan</option>
                    {listKecamatan &&
                      listKecamatan.map((item, index) => (
                        <option value={item.label} key={index}>
                          {item.label}
                        </option>
                      ))}
                  </Input>
                </Col>
                <Col>
                  <Input
                    type="select"
                    id="normal-field"
                    placeholder="desa"
                    value={desa2}
                    onChange={(e) => {
                      setDesa2(e.target.value);
                      cariItems(searchItem, kecamatan2, e.target.value);
                    }}
                    required
                    style={{ paddingRight: 10 }}
                  >
                    <option value={""}> pilih Desa</option>
                    {listDesaPilihan2 &&
                      listDesaPilihan2.map((item, index) => (
                        <option value={item.label} key={index}>
                          {item.label}
                        </option>
                      ))}
                  </Input>
                </Col>
              </Row>
            </FormGroup>
            <div style={containerStyle}>
              <ul className="list-group">
                {listSelected ? (
                  listSelected.map((item) => (
                    <li className="list-group-item" key={item.id}>
                      <div className={s.listBadge}>
                        {item.nama} <code>{item.desa}</code>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Badge
                            color="secondary"
                            style={{ cursor: "pointer", marginRight: 5 }}
                            onClick={() => {
                              setModalOpen2(true);
                              setInfoSelected(item);
                            }}
                          >
                            <i className="fa fa-info mr-2" />
                          </Badge>
                          <Badge
                            color="primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              const data = {};
                              data.nik = item.NIK;
                              data.tahun = tahun;
                              data.noKK = isCheckedDTKS ? "" : item.noKK;

                              item.dob = isCheckedDTKS
                                ? setTanggalLahirDtks(item.tanggalLahir)
                                : setTanggalLahirDukcapil(item.tanggalLahir);

                              setPreSelectedItem(item);
                              setSinkronItem();
                              validasiPenerima(data);
                              setIsCheckedSinkron(false);

                              // setDataSelected(item);

                              //setModalOpen(false);
                            }}
                          >
                            <i className="fa fa-plus mr-2" />
                          </Badge>
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <div />
                )}
              </ul>
            </div>
          </Widget>
        </ModalBody>

        <ModalFooter>
          <div className="mx-auto">
            <Button
              className="btn-rounded"
              color="primary"
              onClick={() => {
                setPreSelectedItem("");
                setListSelected([]);
                setModalOpen(false);
              }}
            >
              Tutup
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalOpen2}>
        <ModalBody>
          <div className="mt-2 mb-3 body-3 muted">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <Badge color="info" style={{ cursor: "pointer" }}>
                Informasi penerima bantuan
              </Badge>
              <Button
                className={s.controlBtn}
                color="primary"
                size="xs"
                onClick={() => {
                  const data = {};
                  data.kolom = "nik";
                  data.data = infoSelected.NIK;
                  openModalHistori(data);
                }}
              >
                History bantuan
              </Button>
            </div>
          </div>
          <Widget className="widget-p-md">
            {infoSelected && (
              <div>
                <Row>
                  <Col>Nama</Col>
                  <Col>
                    <div style={{ color: "red" }}>{infoSelected.nama}</div>
                  </Col>
                </Row>

                {isCheckedDTKS ? (
                  <Row>
                    <Col>Id Keluarga</Col>
                    <Col>{infoSelected.idKeluarga}</Col>
                  </Row>
                ) : (
                  <Row style={{ display: "flex", flexDirection: "row" }}>
                    <Col>Nomor KK</Col>
                    <Col>{infoSelected.noKK}</Col>
                  </Row>
                )}

                {isCheckedDTKS && (
                  <div>
                    <Row>
                      <Col>Kode Kemendagri</Col>
                      <Col>{infoSelected.kodeKemdagri}</Col>
                    </Row>
                    <Row>
                      <Col>Id Individu</Col>
                      <Col>{infoSelected.idIndividu}</Col>
                    </Row>
                    <Row>
                      <Col>Desil Kesejahteraan</Col>
                      <Col>{infoSelected.desilKesejahteraan}</Col>
                    </Row>
                  </div>
                )}
                <Row>
                  <Col>NIK</Col>
                  <Col>{infoSelected.NIK}</Col>
                </Row>
                <Row>
                  <Col>Jenis Kelamin</Col>
                  <Col>{infoSelected.jenisKelamin}</Col>
                </Row>
                <Row>
                  <Col>Hubungan dengan KK</Col>
                  <Col>{infoSelected.hubunganKK}</Col>
                </Row>

                <Row>
                  <Col>Alamat</Col>
                  <Col>{infoSelected.alamat}</Col>
                </Row>

                <Row>
                  <Col>Tanggal Lahir</Col>
                  <Col>{infoSelected.tanggalLahir}</Col>
                </Row>
                <Row>
                  <Col>Status Kawin</Col>
                  <Col>{infoSelected.statusKawin}</Col>
                </Row>
                <Row>
                  <Col>Pekerjaan</Col>
                  <Col>{infoSelected.pekerjaan}</Col>
                </Row>
                <Row>
                  <Col>Pendidikan</Col>
                  <Col>{infoSelected.pendidikan}</Col>
                </Row>
                {isCheckedDTKS && (
                  <div>
                    <Row>
                      <Col>BPNPT</Col>
                      <Col>{infoSelected.BPNPT}</Col>
                    </Row>
                    <Row>
                      <Col>BPUM</Col>
                      <Col>{infoSelected.BPUM}</Col>
                    </Row>
                    <Row>
                      <Col>BST</Col>
                      <Col>{infoSelected.BST}</Col>
                    </Row>
                    <Row>
                      <Col>PKH</Col>
                      <Col>{infoSelected.PKH}</Col>
                    </Row>
                    <Row>
                      <Col>SEMBAKO</Col>
                      <Col>{infoSelected.SEMBAKO}</Col>
                    </Row>
                    <Row>
                      <Col>Resiko Stunting</Col>
                      <Col>{infoSelected.resikoStunting}</Col>
                    </Row>
                  </div>
                )}
                {isCheckedDukcapil && (
                  <div>
                    <Row>
                      <Col>Nama Ayah</Col>
                      <Col>{infoSelected.namaAyah}</Col>
                    </Row>
                    <Row>
                      <Col>Nama Ibu</Col>
                      <Col>{infoSelected.namaIbu}</Col>
                    </Row>
                  </div>
                )}
              </div>
            )}
          </Widget>
        </ModalBody>

        <ModalFooter>
          <div className="mx-auto">
            <Button
              className="btn-rounded"
              color="primary"
              onClick={() => setModalOpen2(false)}
            >
              Ok
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal size="md" isOpen={modaHistori} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>
          {"Histori bantuan"}
        </ModalHeader>
        <ModalBody>
          <Widget className="widget-p-md">
            <div style={containerStyle}>
              <ul className="list-group">
                {listHistoriBantuan ? (
                  listHistoriBantuan.length > 0 ? (
                    listHistoriBantuan.map((item) => (
                      <li className="list-group-item" key={item.id}>
                        <div className={s.listBadge}>
                          {item.tahun}
                          <ul className="list-group">
                            {item.matchedItems.map((itemKu) => (
                              <li className="list-group-item" key={item.id}>
                                <code>{itemKu.bantuan}</code>{" "}
                                {itemKu.nilaiUang
                                  ? "Rp." + numberWithCommas(itemKu.nilaiUang)
                                  : ""}
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  {itemKu.status ? (
                                    <CustomPopover
                                      className={`${s.controlBtn} mx-2`}
                                      size="xs"
                                      color={
                                        itemKu.status == "Hijau"
                                          ? "success"
                                          : itemKu.status == "Orange"
                                          ? "warning"
                                          : itemKu.status == "Merah"
                                          ? "danger"
                                          : itemKu.status == "Hitam"
                                          ? "default"
                                          : ""
                                      }
                                      id={`Popover-${itemKu.id}`}
                                      placement={"top"}
                                      btnLabel={itemKu.status}
                                      title={"Catatan"}
                                      text={itemKu.catatan}
                                    />
                                  ) : (
                                    <div />
                                  )}
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    ))
                  ) : (
                    <div className={s.listBadge}>Belum ada data</div>
                  )
                ) : (
                  <div className={s.listBadge}>Belum ada data</div>
                )}
              </ul>
            </div>
          </Widget>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => closeModal()}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <Modal size="md" isOpen={modaValidasi} toggle={() => closeModal2()}>
        <ModalHeader toggle={() => closeModal2()}>
          {"Hasil Validasi"}
        </ModalHeader>
        <ModalBody>
          <Widget className="widget-p-md">
            <Row style={{ marginBottom: 10 }}>
              {" "}
              <Col md={8}>
                {" "}
                KK memiliki anggota keluarga yang difabel{" "}
                <img
                  style={{ width: 25, height: 25, marginRight: 20 }}
                  src={Difabel}
                />
              </Col>
              <Col md={4}>
                {" "}
                {kkDifable.status ? (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setModalDifabel(true)}
                  >
                    <img style={{ width: 25, height: 25 }} src={Success} />{" "}
                    <code>klik </code>
                  </div>
                ) : (
                  <div style={{ cursor: "pointer" }}>
                    <img style={{ width: 25, height: 25 }} src={Stop} />
                  </div>
                )}
              </Col>
            </Row>

            <ul className="list-group">
              <li className="list-group-item" key={1}>
                <Row>
                  <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    <b> {isCheckedDTKS ? "DTKS" : "Dukcapil"}</b>
                  </Col>
                  <Col
                    style={{ display: "flex", justifyContent: "center" }}
                  ></Col>
                  <Col
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <b> {isCheckedDTKS ? "Dukcapil" : "DTKS"}</b>
                  </Col>
                </Row>
              </li>
              <li className="list-group-item" key={1}>
                <Row>
                  <Col
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    md={5}
                  >
                    {preSelectedItem.nama}
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                    md={2}
                  >
                    <img
                      src={
                        sinkronItem
                          ? sinkronItem.nama == preSelectedItem.nama
                            ? Success
                            : Stop
                          : Stop
                      }
                      style={{ height: 40, width: 40 }}
                    />
                  </Col>
                  <Col
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    md={5}
                  >
                    {sinkronItem ? sinkronItem.nama : ""}
                  </Col>
                </Row>
              </li>
              <li className="list-group-item" key={1}>
                <Row>
                  <Col
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    md={5}
                  >
                    <Col>{preSelectedItem.NIK}</Col>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                    md={2}
                  >
                    <img
                      src={
                        sinkronItem
                          ? sinkronItem.NIK == preSelectedItem.NIK
                            ? Success
                            : Stop
                          : Stop
                      }
                      style={{ height: 40, width: 40 }}
                    />
                  </Col>
                  <Col
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    md={5}
                  >
                    <Col>{sinkronItem ? sinkronItem.NIK : ""}</Col>
                  </Col>
                </Row>
              </li>
              <li className="list-group-item" key={1}>
                <Row>
                  <Col
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    md={5}
                  >
                    <Col>{preSelectedItem.dob}</Col>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                    md={2}
                  >
                    <img
                      src={
                        sinkronItem
                          ? sinkronItem.dob == preSelectedItem.dob
                            ? Success
                            : Stop
                          : Stop
                      }
                      style={{ height: 40, width: 40 }}
                    />
                  </Col>
                  <Col
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    md={5}
                  >
                    <Col>{sinkronItem ? sinkronItem.dob : ""}</Col>
                  </Col>
                </Row>
              </li>
              <li className="list-group-item" key={1}>
                <Row>
                  <Col
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    md={5}
                  >
                    <Col>
                      {isCheckedDTKS
                        ? preSelectedItem.desa
                        : preSelectedItem.alamat}
                    </Col>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                    md={2}
                  >
                    <img
                      src={
                        sinkronItem
                          ? isCheckedDTKS
                            ? sinkronItem.alamat == preSelectedItem.desa
                              ? Success
                              : sinkronItem.desa == preSelectedItem.alamat
                              ? Success
                              : Stop
                            : sinkronItem.desa == preSelectedItem.alamat
                            ? Success
                            : Stop
                          : Stop
                      }
                      style={{ height: 40, width: 40 }}
                    />
                  </Col>
                  <Col
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    md={5}
                  >
                    <Col>
                      {sinkronItem
                        ? isCheckedDTKS
                          ? preSelectedItem.desa
                          : preSelectedItem.alamat
                        : ""}
                    </Col>
                  </Col>
                </Row>
              </li>
            </ul>
            {sinkronItem && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: 10,
                  marginTop: 10,
                }}
              >
                <div className="checkbox checkbox-primary">
                  <input
                    className="styled"
                    id="checkbox115"
                    type="checkbox"
                    name="sinkron"
                    checked={isCheckedSinkron}
                    onChange={handleCheckboxChange3}
                  />
                  <label for="checkbox115" />
                </div>
                <div>
                  <code> Data DTKS dan Dukcapil sinkron </code>
                </div>
              </div>
            )}
          </Widget>
        </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            onClick={() => {
              const data = {};
              data.kolom = "nik";
              data.data = nikValidasi;
              openModalHistori(data);
              closeModal2();
            }}
            size="sm"
          >
            History
          </Button>
          <Button color="primary" onClick={() => closeModal2()} size="sm">
            Cancel
          </Button>
          <Button
            color={hasilValidasi ? "success" : "danger"}
            onClick={() => {
              setDataSelected(preSelectedItem);
              setModalOpen(false);
              closeModal2();
            }}
            size="sm"
          >
            Tambahkan
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size="lg"
        isOpen={modaValidasiBantuan}
        toggle={() => closeModal3()}
      >
        <ModalHeader toggle={() => closeModal3()}>
          Hasil Validasi berdasarkan <code> Perbup 322.a/KEP/HK/2022 </code>{" "}
          Tentang Kriteria Penerima Bantuan Sosial
        </ModalHeader>
        <ModalBody>
          <ul className="list-group">
            <li className="list-group-item" key={1}>
              <Row>
                <Col className="pr-grid-col" xs={12} lg={4}>
                  Total bantuan diterima oleh <code> {nama}</code> dalam tahun{" "}
                  <code> {tahun}</code> ditambah nilai uang bantuan ini tidak
                  melebihi <code>Rp. 50.000.000</code>
                </Col>
                <Col className="pr-grid-col" xs={12} lg={6}>
                  <div className="d-flex flex-column mt-3">
                    <div className={s.activity}>
                      <p className="body-2">
                        {validasiAkumulasiBantuan
                          ? "Rp." +
                            numberWithCommas(
                              parseInt(validasiAkumulasiBantuan.total)
                            )
                          : 0}
                        <span className="body-3 muted">/{"Rp.50.000.000"}</span>
                      </p>
                    </div>
                    <Progress
                      color={
                        validasiAkumulasiBantuan
                          ? (parseInt(validasiAkumulasiBantuan.total) /
                              50000000) *
                              100 <
                            90
                            ? "success"
                            : "danger"
                          : "primary"
                      }
                      className="progress-xs"
                      value={
                        validasiAkumulasiBantuan
                          ? (parseInt(validasiAkumulasiBantuan.total) /
                              50000000) *
                            100
                          : 0
                      }
                    />
                  </div>
                </Col>
                <Col className="pr-grid-col" xs={12} lg={2}>
                  <img
                    src={limaPuluhJuta ? Success : Stop}
                    style={{ height: 40, width: 40 }}
                  />
                </Col>
              </Row>
            </li>
            <li className="list-group-item" key={2}>
              <Row>
                <Col className="pr-grid-col" xs={12} lg={4}>
                  Total penerima bantuan belum mencapai kuota{" "}
                  <code>
                    {validasiTotalPenerima
                      ? validasiTotalPenerima.totalPenerima + " orang"
                      : ""}
                  </code>
                </Col>
                <Col className="pr-grid-col" xs={12} lg={6}>
                  <div className="d-flex flex-column mt-3">
                    <div className={s.activity}>
                      <p className="body-2">
                        {validasiTotalPenerima
                          ? Math.round(
                              (validasiTotalPenerima.penerima /
                                validasiTotalPenerima.totalPenerima) *
                                100
                            )
                          : ""}
                        % input data{" "}
                      </p>
                      <p className="body-2">
                        {validasiTotalPenerima
                          ? validasiTotalPenerima.penerima
                          : 0}
                        <span className="body-3 muted">
                          /
                          {validasiTotalPenerima
                            ? validasiTotalPenerima.totalPenerima
                            : 0}
                        </span>
                      </p>
                    </div>
                    <Progress
                      color={quota ? "success" : "danger"}
                      className="progress-xs"
                      value={
                        validasiTotalPenerima
                          ? (validasiTotalPenerima.penerima /
                              validasiTotalPenerima.totalPenerima) *
                            100
                          : 0
                      }
                    />
                  </div>
                </Col>
                <Col className="pr-grid-col" xs={12} lg={2}>
                  <img
                    src={quota ? Success : Stop}
                    style={{ height: 40, width: 40 }}
                  />
                </Col>
              </Row>
            </li>
            <li className="list-group-item" key={3}>
              <Row>
                <Col className="pr-grid-col" xs={12} lg={4}>
                  Total Penerima bantuan berstatus <code>Miskin Ekstrim</code>
                </Col>
                <Col className="pr-grid-col" xs={12} lg={6}>
                  <div className="d-flex flex-column mt-3">
                    <div className={s.activity}>
                      <p className="body-2">
                        {validasiTotalPenerima
                          ? Math.round(
                              (validasiTotalPenerima.penerimaMiskinEkstrim /
                                validasiTotalPenerima.totalPenerima) *
                                100
                            )
                          : ""}
                        % miskin esktrim{" "}
                      </p>
                      <p className="body-2">
                        {validasiTotalPenerima
                          ? validasiTotalPenerima.penerimaMiskinEkstrim
                          : 0}
                        <span className="body-3 muted">
                          /
                          {validasiTotalPenerima
                            ? validasiTotalPenerima.totalPenerima
                            : 0}
                        </span>
                      </p>
                    </div>
                    <Progress
                      color={limaPuluhME ? "success" : "danger"}
                      className="progress-xs"
                      value={
                        validasiTotalPenerima
                          ? (validasiTotalPenerima.penerimaMiskinEkstrim /
                              validasiTotalPenerima.totalPenerima) *
                            100
                          : 0
                      }
                    />
                  </div>
                </Col>
                <Col className="pr-grid-col" xs={12} lg={2}>
                  <img
                    src={limaPuluhME ? Success : Stop}
                    style={{ height: 40, width: 40 }}
                  />
                </Col>
              </Row>
            </li>
            <li className="list-group-item" key={3}>
              <Row>
                <Col className="pr-grid-col" xs={12} lg={4}>
                  Penerima bantuan bernama <code>{nama}</code> tidak memiliki
                  catatan buruk dalam <code>5 tahun terakhir</code>
                </Col>
                <Col className="pr-grid-col" xs={12} lg={6}>
                  <div className="d-flex flex-column mt-3">
                    <div className={s.activity}>
                      <p className="body-2">
                        {catatanBuruk ? "Ya, " : "Tidak,  "}{" "}
                        <span className="body-3 muted">Lihat</span>
                        <Badge
                          color={catatanBuruk ? "default" : "success"}
                          onClick={() => {
                            const data = {};
                            data.kolom = "nik";
                            data.data = nik;
                            openModalHistori(data);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          History
                        </Badge>
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="pr-grid-col" xs={12} lg={2}>
                  <img
                    src={catatanBuruk ? Stop : Success}
                    style={{ height: 40, width: 40 }}
                  />
                </Col>
              </Row>
            </li>
            {checkBantuanTahunSama ? (
              <li className="list-group-item" key={4}>
                <Row>
                  <Col className="pr-grid-col" xs={12} lg={10}>
                    Penerima bantuan bernama <code>{nama}</code> sudah diinput
                    untuk bantuan yang sama pada tahun <code>{tahun}</code>.
                    Periksa kembali NIK. Validasi berdasarkan NIK
                  </Col>

                  <Col className="pr-grid-col" xs={12} lg={2}>
                    <img src={Stop} style={{ height: 40, width: 40 }} />
                  </Col>
                </Row>
              </li>
            ) : (
              <div />
            )}
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              closeModal3();
              setCheckBantuanTahunSama(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color={
              currentBantuan && currentBantuan.kunciAturan == "Ya"
                ? catatanBuruk || !limaPuluhJuta || !limaPuluhME
                  ? "danger"
                  : !quota || checkBantuanTahunSama
                  ? "danger"
                  : "success"
                : !quota || checkBantuanTahunSama
                ? "danger"
                : catatanBuruk || !limaPuluhJuta || !limaPuluhME
                ? "warning"
                : "success"
            }
            onClick={() => {
              lanjutkan();
              closeModal3();
            }}
            disabled={
              currentBantuan && currentBantuan.kunciAturan == "Ya"
                ? catatanBuruk || !limaPuluhJuta || !limaPuluhME
                  ? true
                  : !quota || checkBantuanTahunSama
                  ? true
                  : false
                : !quota || checkBantuanTahunSama
                ? true
                : false
            }
            className="px-3"
          >
            Simpan
          </Button>
          <div
            style={{ marginRight: 20, marginLeft: 20, cursor: "pointer" }}
            onClick={() => setModalKunci(true)}
          >
            <img
              src={
                currentBantuan && currentBantuan.kunciAturan == "Ya"
                  ? Locked
                  : UnLocked
              }
            />{" "}
            <code>klik</code>
          </div>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalNik} toggle={() => setModalNik(false)}>
        <ModalHeader toggle={() => setModalNik(false)}>
          Hasil Validasi NIK
        </ModalHeader>
        <ModalBody>
          <Widget className="widget-p-md">
            Nomor Induk Kependudukan dari <code> {nama} </code> harus berjumlah{" "}
            <code> 16 karakter</code>.
          </Widget>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" onClick={() => setModalNik(false)}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalDifabel} toggle={() => setModalDifabel(false)}>
        <ModalHeader toggle={() => setModalDifabel(false)}>
          Hasil Validasi KK Difabel
        </ModalHeader>
        <ModalBody>
          <Widget className="widget-p-md">
            Keluarga ini memili anggota keluarga yang berstatus{" "}
            <code> difabel </code>.
            <ul className="list-group">
              <li className="list-group-item" key={1}>
                <Row>
                  <Col
                    md={4}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    Nama
                  </Col>

                  <Col
                    md={8}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <code> {kkDifable.data && kkDifable.data.nama} </code>
                  </Col>
                </Row>
              </li>
              <li className="list-group-item" key={1}>
                <Row>
                  <Col
                    md={4}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    Kategori difabel
                  </Col>

                  <Col
                    md={8}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    {kkDifable.data && kkDifable.data.jenisCacat}
                  </Col>
                </Row>
              </li>
              <li className="list-group-item" key={1}>
                <Row>
                  <Col
                    md={4}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    Bantuan yang diperlukan
                  </Col>

                  <Col
                    md={8}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    {kkDifable.data && kkDifable.data.bantuanPerlu}
                  </Col>
                </Row>
              </li>
              <li className="list-group-item" key={1}>
                <Row>
                  <Col
                    md={4}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    Potensi dan Non Potensi
                  </Col>

                  <Col
                    md={8}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    {kkDifable.data && kkDifable.data.potensiNon}
                  </Col>
                </Row>
              </li>
            </ul>
          </Widget>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={() => setModalDifabel(false)}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalKunci} toggle={() => setModalKunci(false)}>
        <ModalHeader toggle={() => setModalKunci(false)}>
          Status Bantuan{" "}
          <img
            src={
              currentBantuan && currentBantuan.kunciAturan == "Ya"
                ? Locked
                : UnLocked
            }
          />
        </ModalHeader>
        <ModalBody>
          {currentBantuan && currentBantuan.kunciAturan == "Ya" ? (
            <Widget className="widget-p-md">
              Bantuan sosial <code> {bantuan} </code> sementara{" "}
              <code> terkunci. </code> <br /> Konsekuensinya penerima bantuan
              yang tidak memenuhi persyaratan berdasarkan{" "}
              <code>Perbup 322.a/KEP/HK/2022</code> tidak bisa diinput ke dalam
              sistem. <br />
              Untuk membuka kunci silahkan menghubungi Bapelitbang Kab. Rote
              Ndao.
            </Widget>
          ) : (
            <Widget className="widget-p-md">
              Bantuan sosial <code> {bantuan} </code> sementara{" "}
              <code> terbuka </code> <br /> Konsekuensinya penerima bantuan yang
              tidak lolos validasi tetap bisa diinput ke dalam sistem.
              <br />
              Untuk mengunci kembali silahkan menghubungi Bapelitbang Kab. Rote
              Ndao.
            </Widget>
          )}
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={() => setModalKunci(false)}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );

  return renderForm();
};

export default PenerimaForm;
