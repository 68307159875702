import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
  Badge,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";

import cloudIcon from "../../assets/tables/cloudIcon.svg";
import funnelIcon from "../../assets/tables/funnelIcon.svg";
import optionsIcon from "../../assets/tables/optionsIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import searchIcon from "../../assets/tables/searchIcon.svg";
import moreIcon from "../../assets/tables/moreIcon.svg";
import TulufaliLogo from "../../components/Icons/tulufali_logo.png";

import s from "./Tables.module.scss";
import mock from "./mock.js";
import {
  GET_ASUPAN,
  API_TIMEOUT,
  GET_INDIVIDU_DESIL_DESA,
  PROGRESS_BANTUAN_API,
  GET_BANTUAN_TAHUN_KATEGORI,
} from "../../utils/index.js";
import axios from "axios";
import { logApp } from "../../utils/func.js";

import { push } from "connected-react-router";
import { useDispatch } from "react-redux";

const TableProgress = function () {
  const [firstTable, setFirstTable] = useState([]);
  const [secondTable] = useState(mock.secondTable);
  const [transactions, setTransactions] = useState(mock.transactionsWidget);
  const [tasks, setTasks] = useState(mock.tasksWidget);
  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const [tableDropdownOpen, setTableMenuOpen] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [idHapus, setIdHapus] = useState();
  const [listPenerima, setListPenerima] = useState([]);
  const [simpleDropdownValue2, setSimpleDropdownValue2] = useState("2023");
  const [bantuanModal, setBantuanModal] = useState("");

  const dispatch = useDispatch();

  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      setCurrentUser(currentUser);
      logApp(currentUser.uid, "Proses Input");
    }
  }, []);

  const containerStyle = {
    width: "100%",
    height: "300px", // Atur tinggi sesuai kebutuhan Anda
    overflow: "auto",
    /* Gaya tampilan scroll */
    scrollbarWidth: "thin",
    scrollbarColor: "#888888 #f0f0f0",
  };

  useEffect(() => {
    ambilProgresBantuan("2023");
  }, []);

  const ambilProgresBantuan = (tahun) => {
    axios({
      method: "get",
      url: PROGRESS_BANTUAN_API + tahun,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;
          const filter = data.filter((item) => {
            return item.jumlahpenerima < item.jmlPenerima;
          });

          setFirstTable(filter);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };
  const listTahun = [
    { tahun: 2018 },
    { tahun: 2019 },
    { tahun: 2020 },
    { tahun: 2021 },
    { tahun: 2022 },
    { tahun: 2023 },
    { tahun: 2024 },
    { tahun: 2025 },
    { tahun: 2026 },
  ];

  const changeSelectDropdownSimple2 = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue2(e.currentTarget.textContent);
    const curAnak = listTahun.filter((item) => {
      return item.tahun == select;
    });

    ambilProgresBantuan(curAnak[0].tahun);
    // ambilDataBantuanKategori(currentBantuan, curAnak[0].tahun);
  };

  const ambilDataBantuanTahun = (data) => {
    axios({
      method: "post",
      url: GET_BANTUAN_TAHUN_KATEGORI,
      timeout: API_TIMEOUT,
      data: data,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setListPenerima(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const pageSize = 100;
  const firstTablePagesCount = Math.ceil(firstTable.length / pageSize);
  const secondTablePagesCount = Math.ceil(secondTable.length / pageSize);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };

  const tableMenuOpen = () => {
    setTableMenuOpen(!tableDropdownOpen);
  };

  const toggleTask = (id) => {
    setTasks(
      tasks.map((task) => {
        if (task.id === id) {
          task.completed = !task.completed;
        }
        return task;
      })
    );
  };

  const openModal = (data) => {
    ambilDataBantuanTahun(data);

    setModalDelete(true);
  };
  const closeModal = () => {
    setModalDelete(false);
  };

  return (
    <div>
      <Row>
        <Col>
          <Row className="mb-4">
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className="headline-2">
                    Progress Penginputan Bantuan Sosial{" "}
                  </div>

                  <div className="d-flex">
                    <Col className="pr-grid-col" xs={4}>
                      <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="primary">
                          {simpleDropdownValue2}
                        </DropdownToggle>

                        <DropdownMenu>
                          {listTahun &&
                            listTahun.map((item) => (
                              <DropdownItem
                                onClick={changeSelectDropdownSimple2}
                              >
                                {item.tahun}
                              </DropdownItem>
                            ))}
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </Col>
                  </div>
                </div>
                <div className="widget-table-overflow">
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    responsive
                  >
                    <thead>
                      <tr>
                        <th className={s.checkboxCol}>
                          <div className="checkbox checkbox-primary">
                            <input
                              className="styled"
                              id="checkbox100"
                              type="checkbox"
                            />
                            <label for="checkbox100" />
                          </div>
                        </th>
                        <th className="w-25">BANTUAN</th>
                        <th className="w-25">TAHUN</th>
                        <th className="w-25">DINAS</th>
                        <th className="w-25">TOTAL PENERIMA</th>
                        <th className="w-25">PENERIMA</th>

                        <th className="w-25">MISKINEKSTRIM</th>
                        <th className="w-25">STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {firstTable
                        .slice(
                          firstTableCurrentPage * pageSize,
                          (firstTableCurrentPage + 1) * pageSize
                        )
                        .map((item) => (
                          <tr key={uuidv4()}>
                            <td>
                              <div className="checkbox checkbox-primary">
                                <input
                                  id={item.id}
                                  className="styled"
                                  type="checkbox"
                                />
                                <Label for={item.id} />
                              </div>
                            </td>
                            <td className="d-flex align-items-center">
                              <span className="ml-3">{item.bantuan}</span>
                            </td>

                            <td>{item.tahun}</td>
                            <td>{item.dinas}</td>
                            <td>{item.jmlPenerima}</td>

                            <td>
                              <div className={`d-flex justify-content-between`}>
                                <div className="d-flex flex-column mt-3">
                                  <div className={s.activity}>
                                    <p className="body-2">
                                      {Math.round(
                                        (item.jumlahpenerima /
                                          item.jmlPenerima) *
                                          100
                                      )}
                                      % Input Penerima{" "}
                                    </p>
                                    <p className="body-2">
                                      {item.jumlahpenerima}
                                      <span className="body-3 muted">
                                        /{item.jmlPenerima}
                                      </span>
                                    </p>
                                  </div>
                                  <Progress
                                    color={
                                      (item.jumlahpenerima / item.jmlPenerima) *
                                        100 >
                                      50
                                        ? "success"
                                        : "danger"
                                    }
                                    className="progress-xs"
                                    value={
                                      (item.jumlahpenerima / item.jmlPenerima) *
                                      100
                                    }
                                  />
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className={`d-flex justify-content-between`}>
                                <div className="d-flex flex-column mt-3">
                                  <div className={s.activity}>
                                    <p className="body-2">
                                      {Math.round(
                                        (item.jumlahmiskinekstrim /
                                          item.jmlPenerima) *
                                          100
                                      )}
                                      % Miskin Esktrim{" "}
                                    </p>
                                    <p className="body-2">
                                      {item.jumlahmiskinekstrim}
                                      <span className="body-3 muted">
                                        /{item.jmlPenerima}
                                      </span>
                                    </p>
                                  </div>
                                  <Progress
                                    color={
                                      (item.jumlahmiskinekstrim /
                                        item.jmlPenerima) *
                                        100 >
                                      50
                                        ? "success"
                                        : "danger"
                                    }
                                    className="progress-xs"
                                    value={
                                      (item.jumlahmiskinekstrim /
                                        item.jmlPenerima) *
                                      100
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              {(item.jumlahpenerima / item.jmlPenerima) * 100 ==
                                100 &&
                              (item.jumlahmiskinekstrim / item.jmlPenerima) *
                                100 ==
                                100 ? (
                                <Button
                                  className={`${s.controlBtn} mx-2`}
                                  color="success"
                                  size="xs"
                                  onClick={() => {
                                    const data = {};
                                    data.bantuan = item.bantuan;
                                    data.tahun = item.tahun;
                                    setBantuanModal(item.bantuan);
                                    openModal(data);
                                  }}
                                >
                                  Selesai
                                </Button>
                              ) : (
                                <Button
                                  className={s.controlBtn}
                                  color="danger"
                                  size="xs"
                                  onClick={() => {
                                    const data = {};
                                    data.bantuan = item.bantuan;
                                    data.tahun = item.tahun;
                                    setBantuanModal(item.bantuan);
                                    openModal(data);
                                  }}
                                >
                                  On Progress
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <Pagination
                    className="pagination-borderless"
                    aria-label="Page navigation example"
                  >
                    <PaginationItem disabled={firstTableCurrentPage <= 0}>
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage - 1)
                        }
                        previous
                        href="#top"
                      />
                    </PaginationItem>
                    {[...Array(firstTablePagesCount)].map((page, i) => (
                      <PaginationItem
                        active={i === firstTableCurrentPage}
                        key={i}
                      >
                        <PaginationLink
                          onClick={(e) => setFirstTablePage(e, i)}
                          href="#top"
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem
                      disabled={
                        firstTableCurrentPage >= firstTablePagesCount - 1
                      }
                    >
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage + 1)
                        }
                        next
                        href="#top"
                      />
                    </PaginationItem>
                  </Pagination>
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal size="md" isOpen={modalDelete} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>{bantuanModal}</ModalHeader>
        <ModalBody>
          <Widget className="widget-p-md">
            <div style={containerStyle}>
              <ul className="list-group">
                {listPenerima ? (
                  listPenerima.length > 0 ? (
                    listPenerima.map((item) => (
                      <li className="list-group-item" key={item.id}>
                        <div className={s.listBadge}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            {item.nama} <code>{item.desa}</code> {item.nik}
                            <code>
                              {item.idKeluarga != ""
                                ? " miskin ekstrim "
                                : " mampu "}
                            </code>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <Badge
                                color="secondary-red"
                                style={{ cursor: "pointer", marginRight: 5 }}
                                onClick={() => {
                                  "";
                                }}
                              >
                                <i className="fa fa-info mr-2" />
                              </Badge>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <div className={s.listBadge}>Belum ada data</div>
                  )
                ) : (
                  <div className={s.listBadge}>Belum ada data</div>
                )}
              </ul>
            </div>
          </Widget>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => closeModal()}>
            Ok
          </Button>
          <Button color="primary" onClick={() => ""}>
            Download
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default TableProgress;
