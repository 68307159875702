import React from 'react';
import FooterIcon from '../Icons/FooterIcon.js';
import s from './Footer.module.scss';

const Footer = () => {
  return (
    <div className={s.footer}>
      <span className={s.footerLabel}>
        2023 &copy; Informatika.RND Hand-crafted & Made with
      </span>
      <FooterIcon />
    </div>
  );
};

export default Footer;
