import React, { useState, useEffect } from 'react';
import AsupanForm from './AsupanForm';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import { Alert } from 'reactstrap';
import cx from 'classnames';

import s from '../Users.module.scss';

const AsupanFormPage = (props) => {
  return (
    <React.Fragment>
      <div className='page-top-line'>
        <h2 className='page-title'>Data Makanan</h2>
        <Alert color='primary' className={''}>
          Aplikasi ini terintegrasi ke{' '}
          <a
            className='text-white font-weight-bold'
            rel='noreferrer noopener'
            href=''
            target='_blank'
          >
            Rote Smart Service
          </a>{' '}
          integration!
        </Alert>
      </div>

      <AsupanForm
        saveLoading={'saveLoading'}
        findLoading={'findLoading'}
        currentUser={'currentUser'}
        record={''}
        isEditing={''}
        isProfile={''}
        onSubmit={'doSubmit'}
        onCancel={''}
      />
    </React.Fragment>
  );
};

function mapStateToProps(store) {
  return {
    findLoading: store.users.form.findLoading,
    saveLoading: store.users.form.saveLoading,
    record: store.users.form.record,
    currentUser: store.auth.currentUser,
  };
}

export default connect(mapStateToProps)(AsupanFormPage);
