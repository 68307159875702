import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
  Badge,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import "./table.css";

import cloudIcon from "../../assets/tables/cloudIcon.svg";
import funnelIcon from "../../assets/tables/funnelIcon.svg";
import optionsIcon from "../../assets/tables/optionsIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import searchIcon from "../../assets/tables/searchIcon.svg";
import moreIcon from "../../assets/tables/moreIcon.svg";
import TulufaliLogo from "../../components/Icons/tulufali_logo.png";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import s from "./Tables.module.scss";
import mock from "./mock.js";
import {
  API_TIMEOUT,
  PROGRESS_BANTUAN_API,
  GET_BANTUAN_TAHUN_KATEGORI,
  UPDATE_SK,
} from "../../utils/index.js";
import axios from "axios";

import { push } from "connected-react-router";
import { useDispatch } from "react-redux";

import Success from "../../assets/success.png";
import { logApp } from "../../utils/func.js";
import swal from "sweetalert";

const TableSK = function () {
  const [firstTable, setFirstTable] = useState([]);
  const [secondTable, setSecondTable] = useState([]);

  const [tasks, setTasks] = useState(mock.tasksWidget);
  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const [tableDropdownOpen, setTableMenuOpen] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const [listPenerima, setListPenerima] = useState([]);
  const [simpleDropdownValue2, setSimpleDropdownValue2] = useState("2023");
  const [bantuanModal, setBantuanModal] = useState("");
  const [tahun, setTahun] = useState("2023");
  const [url, setUrl] = useState("");
  const [dokumenSK, setDokumenSK] = useState("");

  const [modalSK, setModalSk] = useState(false);
  const [idSK, setIdSK] = useState("");

  const dispatch = useDispatch();

  const [currentUser, setCurrentUser] = useState();

  const [tanggalSK, setTanggalSK] = useState("");
  const [nomorSK, setNomorSK] = useState("");

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      setCurrentUser(currentUser);
      logApp(currentUser.uid, "Proses SK Bupati");
    }
  }, []);

  const containerStyle = {
    width: "100%",
    height: "300px", // Atur tinggi sesuai kebutuhan Anda
    overflow: "auto",
    /* Gaya tampilan scroll */
    scrollbarWidth: "thin",
    scrollbarColor: "#888888 #f0f0f0",
  };

  useEffect(() => {
    ambilProgresBantuan(tahun);
  }, []);

  const onChange1 = (e) => {
    setDokumenSK(e.target.files[0]);
  };

  const ambilProgresBantuan = (tahun) => {
    axios({
      method: "get",
      url: PROGRESS_BANTUAN_API + tahun,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;
          const filterProgres = data.filter((item) => {
            return (
              (item.jumlahpenerima / item.jmlPenerima) * 100 >= 100 &&
              item.nomorSK == ""
            );
          });

          setFirstTable(filterProgres);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };
  const listTahun = [
    { tahun: 2018 },
    { tahun: 2019 },
    { tahun: 2020 },
    { tahun: 2021 },
    { tahun: 2022 },
    { tahun: 2023 },
    { tahun: 2024 },
    { tahun: 2025 },
    { tahun: 2026 },
  ];

  const changeSelectDropdownSimple2 = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue2(e.currentTarget.textContent);
    const curAnak = listTahun.filter((item) => {
      return item.tahun == select;
    });

    ambilProgresBantuan(curAnak[0].tahun);
    setTahun(curAnak[0].tahun);
    // ambilDataBantuanKategori(currentBantuan, curAnak[0].tahun);
  };

  const ambilDataBantuanTahun = (data) => {
    axios({
      method: "post",
      url: GET_BANTUAN_TAHUN_KATEGORI,
      timeout: API_TIMEOUT,
      data: data,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          setListPenerima(response.data.data);
          setSecondTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const simpanSK = async () => {
    const formData = new FormData();
    formData.append("photo", dokumenSK);

    formData.append("bantuan", bantuanModal);

    formData.append("tahun", tahun);

    formData.append("tanggalSK", tanggalSK);
    formData.append("nomorSK", nomorSK);

    const response = await axios.patch(UPDATE_SK + idSK, formData, {
      credentials: "include",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${""}`,
      },
    });

    if (response.data.success === 1) {
      setNomorSK("");
      setTanggalSK("");
      setDokumenSK("");
      setIdSK("");

      setModalSk(false);
      setBantuanModal("");

      swal("Success", "Data SK berhasil diinput", "success");
      ambilProgresBantuan(tahun);
    } else {
      swal("Gagal", "Data SK gagal diinput", "failed");
    }
  };

  const pageSize = 10;
  const pageSize2 = 100;
  const firstTablePagesCount = Math.ceil(firstTable.length / pageSize);
  const secondTablePagesCount = Math.ceil(secondTable.length / pageSize2);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };

  const openModal = (data) => {
    ambilDataBantuanTahun(data);
    setModalDelete(true);
  };
  const closeModal = () => {
    setModalDelete(false);
  };

  const openModalSk = (data) => {
    //ambilDataBantuanTahun(data);
    setModalSk(true);
  };
  const closeModalSk = () => {
    setIdSK("");
    setModalSk(false);
  };

  return (
    <div>
      <Row>
        <Col>
          <Row className="mb-4">
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className="headline-2">Surat Keputusan Bupati </div>

                  <div className="d-flex">
                    <Col className="pr-grid-col" xs={4}>
                      <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="primary">
                          {simpleDropdownValue2}
                        </DropdownToggle>

                        <DropdownMenu>
                          {listTahun &&
                            listTahun.map((item) => (
                              <DropdownItem
                                onClick={changeSelectDropdownSimple2}
                              >
                                {item.tahun}
                              </DropdownItem>
                            ))}
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </Col>
                  </div>
                </div>
                <div className="widget-table-overflow">
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    responsive
                  >
                    <thead>
                      <tr>
                        <th className={s.checkboxCol}>
                          <div className="checkbox checkbox-primary">
                            <input
                              className="styled"
                              id="checkbox100"
                              type="checkbox"
                            />
                            <label for="checkbox100" />
                          </div>
                        </th>
                        <th className="w-25">BANTUAN</th>
                        <th className="w-25">TAHUN</th>
                        <th className="w-25">DINAS</th>
                        <th className="w-25">TOTAL PENERIMA</th>
                        <th className="w-25">PENERIMA</th>

                        <th className="w-25">MISKINEKSTRIM</th>
                        <th className="w-25">DATA</th>
                        <th className="w-25">SK</th>
                      </tr>
                    </thead>
                    <tbody>
                      {firstTable
                        .slice(
                          firstTableCurrentPage * pageSize,
                          (firstTableCurrentPage + 1) * pageSize
                        )
                        .map((item) => (
                          <tr key={uuidv4()}>
                            <td>
                              <div className="checkbox checkbox-primary">
                                <input
                                  id={item.id}
                                  className="styled"
                                  type="checkbox"
                                />
                                <Label for={item.id} />
                              </div>
                            </td>
                            <td className="d-flex align-items-center">
                              <span className="ml-3">{item.bantuan}</span>
                            </td>

                            <td>{item.tahun}</td>
                            <td>{item.dinas}</td>
                            <td>{item.jmlPenerima}</td>

                            <td>
                              <div className={`d-flex justify-content-between`}>
                                <div className="d-flex flex-column mt-3">
                                  <div className={s.activity}>
                                    <p className="body-2">
                                      {Math.round(
                                        (item.jumlahpenerima /
                                          item.jmlPenerima) *
                                          100
                                      )}
                                      % Input Penerima{" "}
                                    </p>
                                    <p className="body-2">
                                      {item.jumlahpenerima}
                                      <span className="body-3 muted">
                                        /{item.jmlPenerima}
                                      </span>
                                    </p>
                                  </div>
                                  <Progress
                                    color={
                                      (item.jumlahpenerima / item.jmlPenerima) *
                                        100 >
                                      50
                                        ? "success"
                                        : "danger"
                                    }
                                    className="progress-xs"
                                    value={
                                      (item.jumlahpenerima / item.jmlPenerima) *
                                      100
                                    }
                                  />
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className={`d-flex justify-content-between`}>
                                <div className="d-flex flex-column mt-3">
                                  <div className={s.activity}>
                                    <p className="body-2">
                                      {Math.round(
                                        (item.jumlahmiskinekstrim /
                                          item.jmlPenerima) *
                                          100
                                      )}
                                      % Miskin Esktrim{" "}
                                    </p>
                                    <p className="body-2">
                                      {item.jumlahmiskinekstrim}
                                      <span className="body-3 muted">
                                        /{item.jmlPenerima}
                                      </span>
                                    </p>
                                  </div>
                                  <Progress
                                    color={
                                      (item.jumlahmiskinekstrim /
                                        item.jmlPenerima) *
                                        100 >
                                      50
                                        ? "success"
                                        : "danger"
                                    }
                                    className="progress-xs"
                                    value={
                                      (item.jumlahmiskinekstrim /
                                        item.jmlPenerima) *
                                      100
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <Button
                                className={`${s.controlBtn} mx-2`}
                                color="primary"
                                size="xs"
                                onClick={() => {
                                  const data = {};
                                  data.bantuan = item.bantuan;
                                  data.tahun = item.tahun;
                                  setBantuanModal(item.bantuan);
                                  openModal(data);
                                }}
                              >
                                Data
                              </Button>
                            </td>
                            <td>
                              <Button
                                className={`${s.controlBtn} mx-2`}
                                color="warning"
                                size="xs"
                                onClick={() => {
                                  const data = {};
                                  data.bantuan = item.bantuan;
                                  data.tahun = item.tahun;

                                  setIdSK(item.id);
                                  setBantuanModal(item.bantuan);

                                  openModalSk(data);
                                }}
                              >
                                Upload
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <Pagination
                    className="pagination-borderless"
                    aria-label="Page navigation example"
                  >
                    <PaginationItem disabled={firstTableCurrentPage <= 0}>
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage - 1)
                        }
                        previous
                        href="#top"
                      />
                    </PaginationItem>
                    {[...Array(firstTablePagesCount)].map((page, i) => (
                      <PaginationItem
                        active={i === firstTableCurrentPage}
                        key={i}
                      >
                        <PaginationLink
                          onClick={(e) => setFirstTablePage(e, i)}
                          href="#top"
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem
                      disabled={
                        firstTableCurrentPage >= firstTablePagesCount - 1
                      }
                    >
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage + 1)
                        }
                        next
                        href="#top"
                      />
                    </PaginationItem>
                  </Pagination>
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal size="lg" isOpen={modalDelete} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>
          <div className={s.tableTitle}>
            <div className="headline-2">
              {bantuanModal}
              <code> Tahun {tahun}</code>
            </div>
            <div className="d-flex">
              <a>
                <ReactHTMLTableToExcel
                  table="dataBantuan"
                  filename={
                    "TuluFali Data Bansos-" + bantuanModal + " " + tahun
                  }
                  sheet="Data"
                  className="btnKu"
                />
              </a>
              <a>
                <img src={printerIcon} alt="Printer" />
              </a>
              <a>
                <img
                  className="d-none d-sm-block"
                  src={optionsIcon}
                  alt="Options"
                />
              </a>
              <a>
                <img
                  style={{ width: 30, height: 30 }}
                  src={Success}
                  alt="Funnel"
                />
              </a>
            </div>
          </div>
        </ModalHeader>

        <Widget className="widget-p-md">
          <div className="widget-table-overflow">
            <Table
              id="dataBantuan"
              className={`table-striped table-borderless table-hover ${s.statesTable}`}
              responsive
            >
              <thead>
                <tr>
                  <th className="w-15">TL</th>
                  <th className="w-25">Nama</th>
                  <th className="w-25">Kelompok</th>
                  <th className="w-25">NIK</th>
                  <th className="w-25">Status</th>
                  <th className="w-25">Desa</th>

                  <th className="w-25">Kecamatan</th>
                </tr>
              </thead>
              <tbody>
                {secondTable
                  .slice(
                    secondTableCurrentPage * pageSize2,
                    (secondTableCurrentPage + 1) * pageSize2
                  )
                  .map((item) => (
                    <tr key={uuidv4()}>
                      <td className="d-flex align-items-center">
                        <img
                          className={s.image}
                          src={TulufaliLogo}
                          alt="User"
                        />
                        <span className="ml-3">{item.name}</span>
                      </td>

                      <td>{item.nama}</td>
                      <td>{item.kelompok}</td>
                      <td>{item.nik}</td>
                      <td>
                        <code>
                          {item.idKeluarga != ""
                            ? " miskin ekstrim "
                            : " mampu "}
                        </code>
                      </td>
                      <td>{item.desa}</td>
                      <td>{item.kecamatan}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Pagination
              className="pagination-borderless"
              aria-label="Page navigation example"
            >
              <PaginationItem disabled={secondTableCurrentPage <= 0}>
                <PaginationLink
                  onClick={(e) =>
                    setSecondTablePage(e, secondTableCurrentPage - 1)
                  }
                  previous
                  href="#top"
                />
              </PaginationItem>
              {[...Array(secondTablePagesCount)].map((page, i) => (
                <PaginationItem active={i === secondTableCurrentPage} key={i}>
                  <PaginationLink
                    onClick={(e) => setSecondTablePage(e, i)}
                    href="#top"
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem
                disabled={secondTableCurrentPage >= secondTablePagesCount - 1}
              >
                <PaginationLink
                  onClick={(e) =>
                    setFirstTablePage(e, secondTableCurrentPage + 1)
                  }
                  next
                  href="#top"
                />
              </PaginationItem>
            </Pagination>
          </div>
        </Widget>
      </Modal>
      <Modal size="md" isOpen={modalSK} toggle={() => closeModalSk()}>
        <ModalHeader toggle={() => closeModalSk()}>
          <div className={s.tableTitle}>
            <div className="headline-2">
              {bantuanModal}
              <code> Tahun {tahun}</code>
            </div>
            <div className="d-flex">
              <a>
                <img src={printerIcon} alt="Printer" />
              </a>
              <a>
                <img
                  className="d-none d-sm-block"
                  src={optionsIcon}
                  alt="Options"
                />
              </a>
              <a>
                <img
                  style={{ width: 30, height: 30 }}
                  src={Success}
                  alt="Funnel"
                />
              </a>
            </div>
          </div>
        </ModalHeader>

        <Widget className="widget-p-md">
          <Form onSubmit={(e) => ""}>
            <FormGroup row>
              <Label md={3} for="normal-field" className="text-md-right">
                Nomor SK
              </Label>
              <Col md={9}>
                <Input
                  type="text"
                  id="normal-field"
                  placeholder=""
                  value={nomorSK}
                  onChange={(e) => setNomorSK(e.target.value)}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={3} for="normal-field" className="text-md-right">
                Tanggal SK
              </Label>
              <Col md={9}>
                <Input
                  type="date"
                  id="normal-field"
                  placeholder=""
                  value={tanggalSK}
                  onChange={(e) => setTanggalSK(e.target.value)}
                  required
                />
              </Col>
            </FormGroup>

            <Widget className="widget-p-md">
              <div className="headline-2 mb-3">Upload Dokumen</div>
              <FormGroup row>
                <Label lg="3" className="text-md-right mt-3">
                  File input
                </Label>
                <Col lg="9">
                  <div className="input-group mb-4 px-2 py-2 rounded-pill bg-light-gray">
                    <input
                      id="upload"
                      type="file"
                      accept="application/pdf"
                      onChange={onChange1}
                      className={`form-control border-0 ${s.upload}`}
                    />
                    <label
                      id="upload-label"
                      htmlFor="upload"
                      className={`font-weight-light text-muted ${s.uploadLabel}`}
                    >
                      {dokumenSK ? dokumenSK.name : "Pilih file"}
                    </label>
                    <div className="input-group-append">
                      <label
                        htmlFor="upload"
                        className="btn btn-light m-0 rounded-pill px-4"
                      >
                        <i className="fa fa-cloud-upload mr-2 text-muted"></i>
                      </label>
                    </div>
                  </div>
                </Col>
              </FormGroup>
            </Widget>
          </Form>
        </Widget>
        <ModalFooter>
          <Button color="danger" onClick={() => setModalSk(false)}>
            Batal
          </Button>
          <Button color="success" onClick={() => simpanSK()}>
            Simpan
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default TableSK;
