import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import s from "./Sidebar.module.scss";
import LinksGroup from "./LinksGroup/LinksGroup";
import { changeActiveSidebarItem } from "../../actions/navigation.js";

import TulufaliLogo from "../Icons/tulufali_logo.png";
import "eva-icons/style/eva-icons.css";

const Sidebar = (props) => {
  const { activeItem = "", ...restProps } = props;

  const [burgerBtnToggled, setBurgerBtnToggled] = useState(false);

  const [currentUser, setCurrentUser] = useState();
  const [peran, setPeran] = useState();
  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      setCurrentUser(currentUser);
      setPeran(currentUser.peran);
    }
  }, []);

  useEffect(() => {
    if (props.sidebarOpened) {
      setBurgerBtnToggled(true);
    } else {
      setTimeout(() => {
        setBurgerBtnToggled(false);
      }, 0);
    }
  }, [props.sidebarOpened]);

  return (
    <nav className={cn(s.root, { [s.sidebarOpen]: burgerBtnToggled })}>
      <header className={s.logo}>
        <img style={{ width: 50, height: 50 }} src={TulufaliLogo} />
        <span className={s.title}>TULU FALI</span>
      </header>
      <ul className={s.nav}>
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Dashboard"
          isHeader
          iconName={<i className="eva eva-home-outline" />}
          link="/template/dashboard"
          index="dashboard"
          badge="9"
        />
        <h5 className={[s.navTitle, s.groupTitle].join(" ")}>DATA</h5>

        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Profil Saya"
          isHeader
          iconName={<i className="eva eva-person-outline" />}
          link="/admin/profil"
          index="profil"
          exact={false}
          childrenLinks={[
            {
              header: "Profile",
              link: "/template/user/profile",
            },
          ]}
        />

        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Bantuan"
          isHeader
          iconName={<i className="eva eva-cube" />}
          link="/admin/bantuan"
          index="bantuan"
          exact={false}
          childrenLinks={[
            {
              header: "Jenis bantuan",
              link: "/admin/bantuan/jenis",
            },
            {
              header: "Data bansos",
              link: "/admin/bantuan",
            },
            {
              header: "Input Data Bansos",
              link: "/admin/bantuan/new",
            },
          ]}
        />

        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Penerima"
          isHeader
          iconName={<i className="eva eva-people" />}
          link="/admin/penerima"
          index="penerima"
          exact={false}
          childrenLinks={[
            {
              header: "Data Penerima",
              link: "/admin/penerima",
            },
            {
              header: "Input Data Penerima",
              link: "/admin/penerima/new",
            },
          ]}
        />

        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Desil"
          isHeader
          iconName={<i className="eva eva-grid-outline" />}
          link="/template/desil"
          index="desil"
          childrenLinks={[
            {
              header: "Desa",
              link: "/template/desil/desa",
            },
            {
              header: "Kecamatan",
              link: "/template/desil/kecamatan",
            },
          ]}
        />
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Progres Input"
          isHeader
          iconName={<i className="eva eva-repeat" />}
          link="/template/progressinput"
          index="progressinput"
          childrenLinks={[
            {
              header: "On Progress",
              link: "/template/progressinput/onprogress",
            },
            {
              header: "Selesai",
              link: "/template/progressinput/selesai",
            },
          ]}
        />

        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="SK Bupati"
          isHeader
          iconName={<i className="eva eva-layers" />}
          link="/template/progressSK"
          index="progressSK"
          childrenLinks={[
            {
              header: "On Progress",
              link: "/template/progressSK/onprogress",
            },
            {
              header: "Selesai",
              link: "/template/progressSK/selesai",
            },
          ]}
        />

        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Evaluasi"
          isHeader
          iconName={<i className="eva eva-checkmark" />}
          link="/template/evaluasi"
          index="evaluasi"
          childrenLinks={[
            {
              header: "Evaluasi Penerima",
              link: "/template/evaluasi/inputevaluasi",
            },
          ]}
        />
        {peran ? (
          peran == "super admin" ? (
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header="Admin"
              isHeader
              iconName={<i className="fa fa-child" />}
              link="/admin/listadmin"
              index="listadmin"
              childrenLinks={[
                {
                  header: "Admin TF",
                  link: "/admin/listadmin",
                },
              ]}
            />
          ) : (
            <div />
          )
        ) : (
          <div />
        )}
        {peran ? (
          peran == "super admin" ? (
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header="Kunci"
              isHeader
              iconName={<i className="eva eva-lock" />}
              link="/admin/kunci"
              index="kunci"
              childrenLinks={[
                {
                  header: "Kunci",
                  link: "/admin/kunci/kunciaturan",
                },
              ]}
            />
          ) : (
            <div />
          )
        ) : (
          <div />
        )}
        {/* <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header='Desa'
          isHeader
          iconName={<i className='eva eva-inbox-outline' />}
          link='/template/makan'
          index='makan'
          childrenLinks={[
            {
              header: 'Makan',
              link: '/template/makan/asupan',
            },
          ]}
        /> */}

        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Analitiks"
          isHeader
          iconName={<i className="eva eva-pie-chart-outline" />}
          link="/template/analitik"
          index="analitik"
          childrenLinks={[
            {
              header: "Miskin Ekstrim",
              link: "/template/analitik/miskinekstrim",
            },
            {
              header: "Fasilitas",
              link: "/template/analitik/fasilitas",
            },
            {
              header: "Penerima",
              link: "/template/analitik/penerima",
            },
          ]}
        />

        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Documentation"
          link="/documentation"
          isHeader
          iconName={<i className="eva eva-book-open-outline" />}
          index="documentation"
          label="new"
          target="_blank"
          labelColor="success"
        />
      </ul>
    </nav>
  );
};

Sidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    activeItem: store.navigation.activeItem,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
