import React, { useState } from 'react';

import Widget from '../../../components/Widget/Widget';
import DatePicker from 'react-datepicker';
import {
  Col,
  Row,
  Button,
  ButtonGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Form,
  FormGroup,
  UncontrolledTooltip,
} from 'reactstrap';
import {
  POST_ASUPAN,
  API_TIMEOUT,
  GET_ASUPAN,
  GET_UKURAN,
} from '../../../utils';
import axios from 'axios';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import s from './Elements.module.scss';

import { useDispatch } from 'react-redux';
import { GET_DESA, GET_KECAMATAN } from '../../../utils';
import { push } from 'connected-react-router';
import { update } from 'lodash';

const AsupanForm = () => {
  const [startDate, setStartDate] = useState('');

  const [asupan, setAsupan] = useState('');
  const [ukuran, setUkuran] = useState('');
  const [gram, setGram] = useState('');
  const [energy, setEnergy] = useState('');
  const [protein, setProtein] = useState('');
  const [foto, setFoto] = useState([]);
  const [lemak, setLemak] = useState('');
  const [karbohidrat, setKarbohidrat] = useState('');
  const [serat, setSerat] = useState('');
  const [kolesterol, setKolesterol] = useState('');
  const [vitA, setVitA] = useState('');
  const [vitE, setVitE] = useState('');
  const [vitB1, setVitB1] = useState('');
  const [vitB2, setVitB2] = useState('');
  const [vitB6, setVitB6] = useState('');

  const [acid, setAcid] = useState('');
  const [vitC, setVitC] = useState('');
  const [sodium, setSodium] = useState('');
  const [potassium, setPotassium] = useState('');
  const [calcium, setCalcium] = useState('');
  const [magnesium, setMagnesium] = useState('');
  const [phosphorus, setPhosphorus] = useState('');
  const [iron, setIron] = useState('');
  const [zinc, setZinc] = useState('');
  const [kategori, setKategori] = useState('');

  const [listUkuran, setListUkuran] = useState([]);

  const [urlFile1, setUrlFile1] = useState();

  const [rubahGambar, setRubahGambar] = useState('false');

  const { id } = useParams();
  const { idView } = useParams();
  const dispatch = useDispatch();

  const [idKu, setIdKu] = useState(id);

  useEffect(() => {
    if (id) {
      cariData(id);
    }
    if (idView) {
      cariData(idView);
    }
  }, [id, idView]);

  useEffect(() => {
    getListUkuran();
  }, []);

  const cariData = (id) => {
    const dataKu = { id: id };

    axios({
      method: 'get',
      url: GET_ASUPAN + '/' + id,
      timeout: API_TIMEOUT,

      data: dataKu,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const result = response.data.data[0];

          if (response.data.data.length == 0) {
            return;
          }

          const tgl = new Date(result.dob);

          setAsupan(result.asupan);
          setUkuran(result.ukuran);

          setGram(result.gram);
          setEnergy(result.energy);
          setProtein(result.protein);
          setLemak(result.lemak);
          setKarbohidrat(result.karbohidrat);
          setSerat(result.serat);
          setKolesterol(result.kolesterol);
          setVitA(result.vitA);
          setVitE(result.vitE);
          setVitB1(result.vitB1);
          setVitB2(result.vitB2);
          setVitB6(result.vitB6);
          setAcid(result.acid);

          setVitC(result.vitC);
          setSodium(result.sodium);
          setAcid(result.acid);
          setPotassium(result.potassium);
          setCalcium(result.calcium);
          setMagnesium(result.magnesium);
          setPhosphorus(result.phosphorus);
          setIron(result.iron);
          setZinc(result.zinc);
          setKategori(result.kategori);

          setUrlFile1(result.foto);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const getListUkuran = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_UKURAN);
      data1 = response.data.data;

      if (data1.length > 0) {
        setListUkuran(data1);
      } else {
        setListUkuran([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFile1(URL.createObjectURL(uploaded));
    setFoto(e.target.files[0]);
    setRubahGambar('true');
  }

  const editData = async () => {
    const formData = new FormData();
    formData.append('asupan', asupan);
    formData.append('ukuran', ukuran);
    formData.append('gram', gram);
    formData.append('energy', energy);
    formData.append('protein', protein);
    formData.append('photo', rubahGambar == 'true' ? foto : []);
    formData.append('lemak', lemak);
    formData.append('karbohidrat', karbohidrat);
    formData.append('serat', serat);
    formData.append('kolesterol', kolesterol);

    formData.append('vitA', vitA);
    formData.append('vitE', vitE);
    formData.append('vitB1', vitB1);
    formData.append('vitB2', vitB2);
    formData.append('vitB6', vitB6);
    formData.append('acid', acid);
    formData.append('vitC', vitC);
    formData.append('sodium', sodium);
    formData.append('potassium', potassium);
    formData.append('calcium', calcium);

    formData.append('magnesium', magnesium);
    formData.append('phosphorus', phosphorus);
    formData.append('iron', iron);
    formData.append('zinc', zinc);
    formData.append('kategori', kategori);
    formData.append('rubahGambar', rubahGambar);

    const response = await axios.patch(POST_ASUPAN, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data.success === 1) {
      alert('Data Anak berhasil diedit');
      dispatch(push('/template/asupan'));
    } else {
      alert('Data Anak gagal diinput');
    }
  };

  const tambahData = async () => {
    const formData = new FormData();
    formData.append('asupan', asupan);
    formData.append('ukuran', ukuran);
    formData.append('gram', gram);
    formData.append('energy', energy);
    formData.append('protein', protein);
    formData.append('photo', rubahGambar == 'true' ? foto : []);
    formData.append('lemak', lemak);
    formData.append('karbohidrat', karbohidrat);
    formData.append('serat', serat);
    formData.append('kolesterol', kolesterol);

    formData.append('vitA', vitA);
    formData.append('vitE', vitE);
    formData.append('vitB1', vitB1);
    formData.append('vitB2', vitB2);
    formData.append('vitB6', vitB6);
    formData.append('acid', acid);
    formData.append('vitC', vitC);
    formData.append('sodium', sodium);
    formData.append('potassium', potassium);
    formData.append('calcium', calcium);

    formData.append('magnesium', magnesium);
    formData.append('phosphorus', phosphorus);
    formData.append('iron', iron);
    formData.append('zinc', zinc);
    formData.append('kategori', kategori);

    const response = await axios.post(POST_ASUPAN, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data.success === 1) {
      alert('Data komposisi gizi makanan berhasil diinput');
      setAsupan('');
      setUkuran('');

      setGram('');
      setEnergy('');
      setProtein('');
      setLemak('');
      setKarbohidrat('');
      setSerat('');
      setKolesterol('');
      setVitA('');
      setVitE('');
      setVitB1('');
      setVitB2('');
      setVitB6('');
      setAcid('');

      setVitC('');
      setSodium('');
      setAcid('');
      setPotassium('');
      setCalcium('');
      setMagnesium('');
      setPhosphorus('');
      setIron('');
      setZinc('');
      setKategori('');
      setFoto([]);

      setUrlFile1('');
    } else {
      alert('Data komposisi gizi makanan gagal diinput');
    }
  };

  const simpanData = async (e) => {
    e.preventDefault();
    if (id) {
      editData();
    } else {
      tambahData();
    }
  };

  const renderForm = () => (
    <Widget className='widget-p-md'>
      <div className='headline-2'>
        {id ? 'Edit' : idView ? 'Lihat' : 'Input'}
      </div>
      <FormGroup>
        <Form onSubmit={(e) => simpanData(e)}>
          <legend className='mt-2'>Data Gizi</legend>
          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Asupan
            </Label>
            <Col md={9}>
              <Input
                type='text'
                id='normal-field'
                placeholder='nama'
                value={asupan}
                onChange={(e) => setAsupan(e.target.value)}
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Ukuran rumah tangga
            </Label>
            <Col md={9}>
              <Input
                type='select'
                id='normal-field'
                placeholder='kategori'
                value={ukuran}
                onChange={(e) => setUkuran(e.target.value)}
                required
              >
                <option value={''}> pilih ukuran</option>
                {listUkuran.map((item) => (
                  <option value={item.ukuran}>{item.ukuran}</option>
                ))}
              </Input>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Kategori makanan/minuman
            </Label>
            <Col md={9}>
              <Input
                type='select'
                id='normal-field'
                placeholder='kategori'
                value={kategori}
                onChange={(e) => setKategori(e.target.value)}
                required
              >
                <option value={''}> pilih kategori</option>
                <option value={'makanan'}>makanan</option>
                <option value={'minuman'}>minuman</option>
                <option value={'snack'}>snack</option>
              </Input>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Berat dalam gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='berat'
                value={gram}
                onChange={(e) => setGram(e.target.value)}
                required
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Energy dalam kilo kalori (kkal)
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='energi'
                value={energy}
                onChange={(e) => setEnergy(e.target.value)}
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Protein dalam gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='protein'
                value={protein}
                onChange={(e) => setProtein(e.target.value)}
                required
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Lemak dalam gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='lemak'
                value={lemak}
                onChange={(e) => setLemak(e.target.value)}
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Karbohidrat dalam gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='karbohidrat'
                value={karbohidrat}
                onChange={(e) => setKarbohidrat(e.target.value)}
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Serat dalam gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='serat'
                value={serat}
                onChange={(e) => setSerat(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Kolesterol dalam gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='kolesterol'
                value={kolesterol}
                onChange={(e) => setKolesterol(e.target.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Vitamin A dalam mili gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='vitamin A'
                value={vitA}
                onChange={(e) => setVitA(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Vitamin E dalam mili gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='vitamin E'
                value={vitE}
                onChange={(e) => setVitE(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Vitamin B1 dalam mili gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='vitamin B1'
                value={vitB1}
                onChange={(e) => setVitB1(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Vitamin B2 dalam mili gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='vitamin B2'
                value={vitB2}
                onChange={(e) => setVitB2(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Vitamin B6 dalam mili gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='vitamin B6'
                value={vitB6}
                onChange={(e) => setVitB6(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Total fol Acid dalam mili gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='acid'
                value={acid}
                onChange={(e) => setAcid(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Vitamin C dalam mili gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='vitamin c'
                value={vitC}
                onChange={(e) => setVitC(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Sodium dalam mili gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='sodium'
                value={sodium}
                onChange={(e) => setSodium(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Potassium dalam mili gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='potassium'
                value={potassium}
                onChange={(e) => setPotassium(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Calcium dalam mili gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='calcium'
                value={calcium}
                onChange={(e) => setCalcium(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Magnesium dalam mili gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='magnesium'
                value={magnesium}
                onChange={(e) => setMagnesium(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Phosphorus dalam mili gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='phosphorus'
                value={phosphorus}
                onChange={(e) => setPhosphorus(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Iron dalam mili gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='iron'
                value={iron}
                onChange={(e) => setIron(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Zinc dalam mili gram
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='zinc'
                value={zinc}
                onChange={(e) => setZinc(e.target.value)}
              />
            </Col>
          </FormGroup>

          <Widget className='widget-p-md'>
            <div className='headline-2 mb-3'>Upload foto makanan</div>
            <FormGroup row>
              <Label lg='3' className='text-md-right mt-3'>
                File input
              </Label>
              <Col lg='9'>
                <div className='input-group mb-4 px-2 py-2 rounded-pill bg-light-gray'>
                  <input
                    id='upload'
                    type='file'
                    onChange={onChange1}
                    className={`form-control border-0 ${s.upload}`}
                  />
                  <label
                    id='upload-label'
                    htmlFor='upload'
                    className={`font-weight-light text-muted ${s.uploadLabel}`}
                  >
                    {foto ? foto.name : 'Pilih file'}
                  </label>
                  <div className='input-group-append'>
                    <label
                      htmlFor='upload'
                      className='btn btn-light m-0 rounded-pill px-4'
                    >
                      <i className='fa fa-cloud-upload mr-2 text-muted'></i>
                    </label>
                  </div>
                </div>
                {urlFile1 && (
                  <div>
                    <div className={`${s.container}`}>
                      <img
                        id='imageResult'
                        src={urlFile1}
                        alt=''
                        className={`${s.imageKu}`}
                      />
                    </div>
                  </div>
                )}
              </Col>
            </FormGroup>
          </Widget>
          {!idView && (
            <FormGroup row>
              <Label md={3} />
              <Col md={9}>
                <Button color='primary' type='submit' className='mr-3 mt-3'>
                  Save Changes
                </Button>
                <Button
                  color='secondary-red'
                  className='mt-3'
                  type='button'
                  onClick={() => dispatch(push(`/admin/users`))}
                >
                  Cancel
                </Button>
              </Col>
            </FormGroup>
          )}
        </Form>
      </FormGroup>
    </Widget>
  );

  return renderForm();
};

export default AsupanForm;
